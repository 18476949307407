@import 'theme/common-style';

.root {
  padding: 36px 48px;

  .forgot {
    margin-top: -24px;
    margin-bottom: 24px;
  }

  form {
    width: 354px;

    .passwordIcon {
      cursor: pointer;
    }
  }

  .authMethodTiles {
    @include flex-container($gap: 48px);
    width: 528px;
  }

  .recoveryCodesButton {
    margin-top: 32px;
  }
}

.newPasswordInput {
  margin-bottom: 15px;
  transition: margin-bottom 0.2s linear;

  &.newPasswordInputInvalid {
    margin-bottom: 60px;
  }
}

.pageContainer {
  padding: 36px 48px;
  color: $color-primary;
}

.paragraph {
  @include font(14);
  margin-top: 24px;
}

.formContainer {
  width: 528px;
  margin-top: 20px;
}

.backLink {
  @include font(12, secondary, 600);
  letter-spacing: 0.67px;
  display: inline-block;
  padding: 14px 28px;
  margin-top: 14px;
}
