@import 'theme/common-style';

.root {
  .searchboxWrapper {
    @include flex-container(flex-start, flex-start, row nowrap, false, 24px);
    margin-bottom: 60px;
    min-width: 354px;

    .searchbox {
      flex-grow: 1;
    }
  }

  .redSymbol {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: $color-redrose;
    color: $color-white;
    padding-top: 2px;
    letter-spacing: 0;
    @include font(11, secondary, 500, 16);
    @include flex-center;
  }
}
