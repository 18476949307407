@import 'theme/common-style';

.permissions {
  margin-top: 8px;

  .permission {
    @include flex-container(flex-start, flex-end);
    @include font(15, primary, 500, 19);
    gap: 16px;
    margin-left: 26px;
    margin-top: 16px;
  }
}
