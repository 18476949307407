@import 'theme/common-style';

.info {
  @include font(13, primary, 400, 16);
}

.error {
  color: $color-error;
}

.sectionWrapper {
  @include flex-container(space-between, flex-start, row nowrap, false, 48px);
  width: 100%;
}
