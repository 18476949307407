@import 'theme/common-style';

.root {
  position: relative;
  display: flex;
  min-height: 100vh;
  margin: -36px -48px;

  &.showingBanner {
    min-height: calc(100vh - $banner-height);
  }

  .rightSide {
    margin-left: $setting-sidebar-width;
    width: calc(100% - $setting-sidebar-width);
    min-width: 600px;
  }
}
