@import 'theme/common-style';

.root {
  @include flex-container(flex-start, flex-start, column);
  width: 100%;
}

.billingCardHeader {
  @include flex-container(space-between, flex-start);
  width: 100%;

  .title {
    @include font(45, secondary, 400, 50);
  }

  @include screen-md-minus {
    @include flex-container(flex-start, flex-start, column, $gap: 40px);
    width: 100%;
  }
}

.avatar {
  @include flex-container(flex-start, center, row nowrap, false, $gap: 8px);
  width: 100%;

  p {
    @include font(14, primary, 400);
    color: $color-primary;
  }
}

.billingSummary {
  @include flex-container(flex-start, flex-start, column);
  width: 36vw;

  .billingLabel {
    @include font(27, secondary, 400, 32);
    margin-bottom: 8.5px;
  }

  .childrenContainer {
    width: 100%;
  }

  .wrapper {
    margin-top: 15.5px;
    background-color: $color-lavender-30;
  }

  .billingSummaryWrapper {
    @include flex-container(space-between, center);
    width: 100%;
    height: 24px;

    p {
      @include font(15, primary, 600, 20);
    }

    span {
      @include font(15, primary, 700, 20);
      @include flex-center;
    }
  }
}

.transactionId {
  @include flex-container(flex-start, center, $gap: 4px);
  width: 100%;

  p {
    @include font(14, primary, 400, 20);
  }

  span {
    @include font(15, primary, 600, 20);
  }

  a {
    text-decoration: underline;

    :hover {
      background-color: $color-lavender;
      opacity: 0.3;
    }
  }
}

.organizationName {
  @include font(15, primary, 600, 20);
  width: 375px;
  @include text-overflow-ellipsis;
  margin: 0 2px;
}
