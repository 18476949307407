@import 'src/theme/common-style';

.root {
  @include flex-container(flex-start, center, row, false, 14px);
  @include font(18, primary, 700, 20);

  &.isSmall {
    @include font(13, primary, 600);
  }
  &.isMedium {
    @include font(14, primary, 600);
  }
  &.isBig {
    @include font(14, primary, 600);
  }
}
