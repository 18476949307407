@import 'theme/common-style';

.root {
  input {
    @include hide-spin-button-on-number-input;
    text-align: end;
    padding-right: 28px;
  }
}

.row {
  @include flex-container(space-between, flex-start, row nowrap, false, 24px);
  margin-bottom: -15px;
  width: 100%;
  max-width: 320px;

  &.withError {
    margin-bottom: 16px;
  }
}

.commissionLabel {
  @include font(15, primary, 500, 20);
  align-self: flex-start;
}

.commissionInput {
  min-width: 148px;
  position: relative;
}
.commissionInputPercent {
  max-width: 122px;
}

.commissionError {
  color: $color-redrose;
  position: absolute;
  right: 48px;
  top: 484px;
  max-width: 320px;
}

.inputError,
.commissionError {
  @include font(12, primary, 500, 15);
}

.priceLabel {
  @include font(18, primary, 700, 20);
  align-self: flex-start;
}

.priceInput {
  width: 320px;

  &.withError {
    margin-bottom: 32px;
  }
}

.symbol {
  @include font(15, primary, 500);
  padding-bottom: 10px;
}
