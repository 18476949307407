@import 'src/theme/common-style';

.root {
  @include flex-container(space-between, flex-end);
  gap: 40px;
  color: $color-primary;
}

.content {
  @include flex-container(space-between, flex-start, column);
  align-self: stretch;
}

.header {
  @include flex-container(flex-start, center);
  gap: 24px;
}

.title {
  @include font(45, secondary, 400, 50);
}

.from {
  @include flex-container(flex-start, center);
  @include font(14);
  gap: 8px;
  margin-top: 4px;
}

.description {
  margin-top: 36px;
}

.author {
  @include font(27, secondary, 400, 32);
}

.nameYear {
  @include font(15, primary, 400, 20);
  margin-top: 8px;
}

.name {
  font-style: italic;
}

.details {
  @include font(14, primary, 400, 20);
  margin-top: 24px;
}

.id {
  @include font(14, secondary, 400, 20);
  margin-top: 5px;
}

.image {
  background-color: $color-white;
  object-fit: contain;
  width: 240px;
  height: 240px;
}
