@import 'theme/common-style';

.root {
  margin-top: 36px;
  margin-bottom: 45px;
}

.billingHeader {
  @include flex-container(space-between, flex-start, row nowrap);
  margin-bottom: -16px;
}

.commissionInput {
  width: 100%;
  margin-top: -8px;
  margin-bottom: 0;
  padding-left: 5.9em;
  text-align: right;

  input {
    @include hide-spin-button-on-number-input;
    text-align: end;
  }
}

.subjectAmount {
  @include flex-container(center, flex-end, column);

  .amount {
    @include font(18, primary, 700, 20);
  }

  .percentage {
    @include font(13, primary, 400, 20);
  }
}

.note {
  @include font(13, $height: 16);
  color: $color-primary;
  margin-bottom: 22px;
}

.commissionPicker {
  padding: 8px 0;

  .radioLabelWrapper {
    width: 110px;

    .commissionAmountInput {
      margin-bottom: 0;
    }

    input {
      @include hide-spin-button-on-number-input;
    }
  }
}

.currencySelectWrapper{
  width: 232px;
}

.inputWrapper {
  input {
    @include hide-spin-button-on-number-input;
  }
}
