@import 'src/theme/common-style';

.header {
  margin-bottom: 24px;
}

.row {
  width: 100%;
  @include flex-container(space-between, center, row nowrap, false, 6px);
  margin-bottom: 24px;
}
