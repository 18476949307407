@import 'theme/common-style';

$circle-symbol-size: 7px;
$tick-symbol-size: 13px;

.root {
  @include flex-container(center, flex-start, column nowrap, false, 24px);
  width: 240px;
}

.step {
  @include flex-container(flex-start, center, row nowrap, false, 16px);
}

.symbol {
  width: $circle-symbol-size;
  height: $circle-symbol-size;
  margin: calc(($tick-symbol-size - $circle-symbol-size) / 2);
  border-radius: 50%;
  border: 1px solid $color-primary;
}

.label {
  @include font(14, primary, 600, 20);
}

.notAccomplished {
  .label {
    font-weight: 400;
    opacity: 50%;
  }
}

.accomplished {
  position: relative;

  .symbol {
    background-image: url('/theme/assets/svg/CheckRounded.svg');
    background-repeat: no-repeat;
    background-size: cover;
    width: $tick-symbol-size;
    height: $tick-symbol-size;
    border: none;
    margin: 0;
  }

  &:not(:first-child)::before {
    content: '';
    left: 6px;
    bottom: 14px;
    background-color: $color-primary;
    width: 1px;
    height: 36px;
    position: absolute;
  }
}

.active {
  .symbol {
    background-color: $color-primary;
  }
}
