@import 'theme/common-style';

.background {
  background-image: $gradient-lavender-white-180;
  background-size: auto 138px;
  background-repeat: no-repeat;
}

.consignmentDetails {
  padding: 40px 48px;
}

.consignee {
  padding-left: 48px;
}
