@import 'src/theme/common-style';

.root {
  @include flex-center;
  gap: 8px;
  padding: 0 26px;
  height: 40px;
  width: fit-content;
  border-radius: 5px;
  border: 1px solid $color-primary;
}

.text {
  @include font(12, secondary, 600, 16);
  padding-top: 2px;
  letter-spacing: 0.67px;
  text-transform: uppercase;
}
