@import 'theme/common-style';

@mixin input-range-track {
  height: 1px;
  background: $color-primary;
}

@mixin input-range-thumb {
  height: 16px;
  width: 16px;
  background-color: $color-primary;
  border-radius: 50%;
  border: 1px solid transparent;
}

@mixin input-range-focus {
  border: 1px solid $color-primary;
  background-color: $color-lavender;
}

.input {
  -webkit-appearance: none;
  appearance: none;

  display: flex;
  background: transparent;
  cursor: pointer;
  width: 100%;
  height: 30px;
  outline: none;

  &::-webkit-slider-runnable-track {
    @include input-range-track();
  }
  &::-moz-range-track {
    @include input-range-track();
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;

    @include input-range-thumb();

    transform: translateY(-50%);
  }
  &::-moz-range-thumb {
    @include input-range-thumb();
  }

  &:focus-visible {
    &::-webkit-slider-thumb {
      @include input-range-focus;
    }
    &::-moz-range-thumb {
      @include input-range-focus;
    }
  }
}

.labels {
  display: flex;
  @include font(13);
}

.label {
  flex: 1;
  text-align: center;
  text-transform: capitalize;

  &:first-child {
    flex: 0.5;
    text-align: left;
  }

  &:last-child {
    text-align: right;
    flex: 0.5;
  }
}
