@import 'theme/common-style';

.root {
  @include flex-container(flex-start, flex-start, column nowrap);
  min-height: 100vh;

  .rightSideWrapper,
  &.empty {
    margin-left: $sidebar-width;
    min-width: calc(100% - $sidebar-width);
    flex-grow: 1;
    background-image: url('/theme/assets/images/bg-desktop.png');
    background-position: top;
    background-attachment: fixed;
    background-size: cover;

    &.isBackgroundColorful {
      background-image: url('/theme/assets/images/bg-color-desktop.png');
    }

    &.showingBanner {
      padding-top: $banner-height;
    }

    .rightSide {
      padding: 36px 48px 16px 48px;
    }
  }

  &.empty {
    margin-left: 0;
  }

  .error {
    @include flex-center;
    flex-direction: column;
    position: fixed;
    width: calc(100% - $sidebar-width);
    right: 0;
    height: 100vh;

    .copy {
      margin-bottom: 20px;
      text-align: center;
      width: 330px;
    }
  }

  .addingFirstOrganization {
    width: 100%;
  }
}
