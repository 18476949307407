@import 'theme/common-style';

.root {
  @include flex-container(flex-start, center, row wrap, false, 24px);
}

.image {
  background-color: $color-white;
  object-fit: contain;
  width: 88px;
  height: 88px;
}
