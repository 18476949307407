@import 'src/theme/common-style';

.root {
  width: 100%;

  background-color: transparent;

  .breadcrumbsWrapper {
    @include flex-container;
    color: $color-white;
    gap: 8px;

    .breadcrumb,
    li {
      list-style: none;
      @include flex-container(center, center);
      gap: 8px;
      text-transform: uppercase;
      @include font(13, secondary, 500);

      .label {
        color: inherit;
        font-size: inherit;
        text-transform: uppercase;
        font-family: inherit;
        line-height: 1;
        height: 24px;
        @include flex-center;
      }

      svg path {
        fill: $color-white;
      }
    }
  }
}
