@import 'theme/common-style';

$transaction-layout-gap: 40px;

.transactionData {
  @include flex-container();
  gap: $transaction-layout-gap;
  min-width: 900px;
  color: $color-primary;

  &.withBackground {
    margin: 48px -48px 0;
    background-color: $color-seasalt;
    padding-left: 48px;
  }
}
.transactionDetails {
  min-width: 528px;
}

.transactionDetails,
.timeline {
  width: calc((100% - $transaction-layout-gap) / 2);
}

.timeline {
  @include flex-container(flex-end);
}
