@import 'theme/common-style';

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $font-primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-variant-numeric: lining-nums;
}

input {
  font-variant-numeric: lining-nums;
}

body.locked {
  overflow: hidden !important;
  position: relative !important;
}

ul {
  list-style: none;
}

img {
  max-width: 100%;
  display: block;
}

button {
  border: none;
  background-color: transparent;
  font-variant-numeric: lining-nums;
  cursor: pointer;
}

a, a:visited {
  text-decoration: none;
  color: inherit;
}

.container {
  @include container;
}

.center {
  text-align: center;
}

.card {
  @include card;
}

.form {
  @include flex-container(center, stretch, column, false, 18px);
  width: 100%;
}

.modal-form {
  @include modal-form;
}
