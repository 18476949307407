@import 'theme/common-style';

.root {
  position: relative;
  margin: 0 -3px;
}

.error {
  @include font(13, primary, 500);
  position: absolute;
  color: $color-error;
  top: -16px;
  left: 0;
}

.fields {
  overflow: hidden;
}

.viewMoreButton {
  // "root" parent used for higher specificity
  .root & {
    @include flex-container(center, center, row-reverse, false, 6px);
    @include font(14, primary, 600);
    margin-top: 10px;
    color: inherit;
    text-decoration: none;
  }
}
