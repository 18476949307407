@import 'theme/common-style';

.root {
  padding: 36px 48px;
  width: 100%;
  min-width: 486px;

}
.wrapper {
  @include flex-container(space-between);
  max-width: 1200px;
}

.userDetails {
  @include flex-container(flex-start, flex-start, column nowrap, false, 20px);
  margin-top:20px
}

.button {
  margin-top:20px
}
