@import 'theme/common-style';

.row {
  @include flex-container(space-between, center);
  @include font(15, $height: 20);
  min-height: 24px;
  margin-top: 16px;
  margin-bottom: 16px;

  &.dark {
    background-color: $color-lavender-30;
  }
  &.light {
    background-color: $color-white-semi-transparent;
  }
  &.indented {
    margin-left: 105px;
    margin-top: 8px;
    margin-bottom: 8px;

    + .row:not(.indented) {
      margin-top: 24px;
    }
  }

  &.twoLines {
    @include flex-container(flex-start, center, column nowrap, false, 16px);

    :first-child {
      align-self: flex-start;
    }

    :last-child {
      align-self: flex-end;
    }
  }
}

.cell {
  min-width: 0;

  &:first-child {
    @include font(15, $weight: 600);
    flex-shrink: 0;
  }
  &:last-child {
    flex-shrink: 0;
    min-width: fit-content;
  }

  &.bold {
    @include font(15, $weight: 700);
  }

  &.split {
    @include flex-container(space-between, center);
    width: 70%;

    > * {
      &:first-child {
        width: 10em;
        text-align: center;
      }
    }
  }
}

.header {
  margin-bottom: 24px;

  &:not(:first-of-type) {
    margin-top: 40px;
  }

  h2 {
    @include font(27, secondary, 400, 32);
  }
}

.subheader {
  @include flex-container(space-between, center);
  @include font(18, primary, 400, 20);
  margin-top: 16px;
  margin-bottom: 24px;

  .cell {
    &:first-child {
      @include font(18, $weight: 700);
    }
  }

  &.twoLines {
    @include flex-container(flex-start, center, column nowrap, false, 16px);

    :first-child {
      align-self: flex-start;
    }

    :last-child {
      align-self: flex-end;
    }
  }
}

.divider {
  height: 1px;
  background-color: $color-french-gray;
}
