@import 'theme/common-style';

.root {
  width: 100%;
  height: 2px;
  background-color: $color-vista-blue;
  position: relative;
  opacity: 0;

  &.isActive {
    opacity: 1;
  }

  .leftTip,
  .rightTip {
    top: -4px;
    box-shadow: 0 2px 0 0 $color-vista-blue;
  }

  .leftTip,
  .leftTip::after,
  .rightTip,
  .rightTip::after {
    position: absolute;
    width: 4px;
    height: 4px;
  }

  .leftTip::after,
  .rightTip::after {
    content: '';
    bottom: -6px;
    box-shadow: 0 -2px 0 0 $color-vista-blue;
  }

  .leftTip {
    left: 0px;
    border-bottom-left-radius: 50%;

    &::after {
      border-top-left-radius: 50%;
    }
  }

  .rightTip {
    right: 0px;
    border-bottom-right-radius: 50%;

    &::after {
      border-top-right-radius: 50%;
    }
  }
}
