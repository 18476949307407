@import 'theme/common-style';

.root {
  @include flex-container(flex-start, flex-start, column);
}

.header {
  text-align: left;
}

.body {
  @include flex-container;
  flex-wrap: wrap;
}

.detailsList dl {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 0px;

  dt,
  dd {
    white-space: wrap;
    flex-wrap: wrap;
  }

  dt {
    width: 110px;
    text-align: left;
    @include font(13, primary, 400);
  }

  dd {
    width: 290px;
    text-align: left;
    @include font(15, primary, 500);
  }
}
