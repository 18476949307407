@import 'src/theme/common-style';

.root {
  @include flex-container(flex-start, flex-start, column, false, 16px);
}

.row {
  @include flex-container(flex-start, center, row wrap, false, 30px);
}

.label {
  min-width: 113px;
  @include font(13, primary, 400);
}

.content {
  @include font(15, primary, 500, 19);
}

.description {
  @include font(14, primary, 400, 20);
  padding-top: 8px;
  width: 100%;
}
