@import 'theme/common-style';

.invoicesInfo {
  @include flex-container(flex-start, flex-start, column);
  width: 100%;

  p {
    @include font(14, primary, 400, 20);
    margin-bottom: 32px;
  }

  .invoicesPayersInfo {
    @include flex-container(flex-start, flex-start, column, $gap: 45px);
  }
}
