@import 'theme/common-style';

.root {
  @include flex-container(flex-start, center);
  position: relative;
  z-index: 10;
  height: 32px;
  width: 45px;
  margin: -12px 0;

  &::after {
    content: '';
    position: absolute;
    width: 480px;
    height: 12px;
  }
}

.dropIndicator {
  position: absolute;
  width: 486px;
}
