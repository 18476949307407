@import 'theme/common-style';

$flag-field-width: 66px;

.root {
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  color: $color-primary;

  &.twoColumnLayout {
    @include flex-container(flex-end, flex-start, row-reverse);
    background-image: url('/theme/assets/images/bg-color-desktop.png');
    background-position: center;
    min-width: fit-content;
    height: 100vh;

    .headerWrapper {
      padding: 36px 48px 12% 49px;
      height: 100%;
      width: 100%;
      @include flex-container(space-between, flex-start, column);
    }

    .formWrapper {
      background-color: $color-seasalt;
      min-width: 528px;
      height: 100%;
      @include flex-container(space-between, flex-start, column);

      .form {
        padding: 0 24px;
      }

      .logo {
        height: 48px;
        width: 48px;
        border-radius: 5px;
        background-color: #d8d8d8;
      }
    }

    .header {
      @include font(26, secondary, 400, 32);
    }
  }

  &.oneColumnLayout {
    @include flex-container(flex-start, center, column, false, 47px);
    background-image: url('/theme/assets/images/bg-desktop.png');
    background-position: top;
    padding: 36px;

    @include screen-xl {
      background-image: url('/theme/assets/images/bg-desktop-wide.png');
    }

    .innerWrapper {
      width: 528px;

      @include screen-max(600px) {
        width: calc(100vw - 72px);
      }
    }
  }
}
// Phone form
.phoneFormWrapper {
  @include flex-container(flex-start, flex-start, column, false, 6px);
  position: relative;
  margin-top: 4px;

  .label {
    @include font(13, primary, 400);
    display: block;
    color: $color-primary;
  }

  .phoneWrapper {
    margin: 0 0 15px 0;
    border-radius: 5px;

    &.phoneWrapperWithError {
      outline: 1px solid $color-error;
    }

    .phoneInput {
      width: calc(100% - $flag-field-width);
      height: 40px;
      margin-left: $flag-field-width;
      padding-left: 16px;
      background-color: $color-lavender-50;
      border-radius: 0 5px 5px 0;
      border: none;
      border-left: 1px solid $color-french-gray;
    }

    .button {
      height: 40px;
      background-color: $color-lavender-50;
      border-radius: 5px 0 0 5px;
      border-right: none;
      border: none;

      :global(.selected-flag) {
        background-color: initial;
        padding: 0 12px 0 16px;
        width: $flag-field-width;

        :global(.arrow) {
          border: none;
          border-right: 1px solid $color-primary;
          border-bottom: 1px solid $color-primary;
          margin-top: -5px;
          margin-left: 8px;
          padding: 3px 3px 3px 3px;
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          transition: 0.15s transform, 0.15s margin-top;
        }

        :global(.up) {
          transform: rotate(225deg);
          -webkit-transform: rotate(225deg);
          margin-top: 0;
        }
      }
    }
  }

  .phoneError {
    @include font(13, primary, 500);
    position: absolute;
    color: $color-error;
    bottom: -8px;
    left: 0;
  }
}

// Verification code form
.inputWrapper {
  @include flex-container;
  gap: 16px;

  .input {
    @include font(15);
    height: 40px;
    width: 34px;
    border-radius: 5px;
    background-color: $color-lavender-50;
    color: $color-primary;
    border: none;
    text-align: center;

    &:focus,
    &:active {
      outline: 1px solid $color-border;
    }
  }
}

.passwordIcon {
  cursor: pointer;
}

.loginByTokenErrorCopy {
  margin-top: 100px;
}

.loginByTokenErrorButtonsWrapper {
  @include flex-container(center, center, row nowrap, false, 16px);
}

.sendNewEmail {
  margin-top: 24px;
}

.verificationEmailOptionsList {
  margin-top: 10px;

  .verificationEmailOption {
    .number {
      @include font(14, primary, 600, 20);
      margin-right: 8px;
    }

    .text {
      @include font(14, primary, 400, 20);
    }
  }
}

.verifyEmail {
  text-align: center;

  .verifyEmailIconWrapper {
    @include flex-container(center);
  }
  .instruction {
    @include font(27, primary, 400, 32);
    margin-top: 27px;
    margin-bottom: 40px;
    max-width: 405px;
  }
}

.authAppCodeInput {
  margin-bottom: 32px;

  input {
    @include hide-spin-button-on-number-input;
  }
}

.sendCodes {
  @include font(14);
  margin-top: 8px;
}

.sendCodesButton {
  margin-left: 0.3em;
}

.submitButton {
  margin-top: 8px;
}

.passwordInput {
  margin-bottom: 15px;
  transition: margin-bottom 0.2s linear;

  &.passwordInputInvalid {
    margin-bottom: 55px;
  }
}

.inlinePhoneNumber {
  display: inline-block;
}

.backLink {
  @include font(12, secondary, 600);
  letter-spacing: 0.67px;
  display: inline-block;
  padding: 14px 28px;

  &.alignCenter {
    margin: 0 auto;
  }
}

.recoveryCodesSubmitButton {
  margin-top: 24px;
}

.useRecoveryCode {
  @include flex-container(flex-start, flex-end, $gap: 4px);
  @include font(14, primary, 400, 20);
  margin-top: 32px;
}

.otpContainer {
  @include flex-container;
  gap: 16px;
}

.otpInput {
  @include hide-spin-button-on-number-input;
  @include font(15);
  height: 40px;
  width: 34px;
  border-radius: 5px;
  background-color: $color-lavender-50;
  color: $color-primary;
  border: none;
  text-align: center;

  &:focus,
  &:active {
    outline: 1px solid $color-border;
  }
}

.inputWithError {
  @include flex-container($flex-flow: column);
  position: relative;
  padding-bottom: 24px;

  .error {
    @include font(13, primary, 500);
    position: absolute;
    color: $color-error;
    bottom: 0;
    left: 0;
  }
}

.formLabel {
  @include font(13, primary, 400);
  display: block;
  color: $color-primary;
  padding-bottom: 6px;
}

.formSubmitButton {
  margin-top: 8px;
}
