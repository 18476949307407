@import 'theme/common-style';

.root {
  @include flex-container(flex-start, center, row nowrap, false);
  width: 100%;
  position: relative;

  &.labelAbove {
    @include flex-container(flex-start, flex-start, column nowrap, false);

    .label {
      @include font(13, primary, 400);
      margin-bottom: 6px;
    }
  }

  .label {
    @include font(15, primary, 500, 19);
    margin-right: 16px;
  }

  .removeIcon {
    position: absolute;
    right: 8px;
    bottom: -5px;
    transform: translateY(-50%);
    cursor: pointer;
    height: 24px;
    color: $color-gray-placeholder;
  }

  .datePicker {
    @include font(15);
    position: relative;
    height: 40px;
    width: 100%;
    background-color: $color-lavender-50;
    border-radius: 5px;
    border: 1px solid $color-lavender-50;
    cursor: pointer;

    &.redBorder {
      outline: 1px solid $color-error;
      background-color: $color-white;
    }

    &:focus,
    &:active {
      border: 1px solid $color-border;
      background-color: $color-white;
      outline: none;
    }
  }

  :global(.react-datepicker-wrapper) {
    width: 100%;
  }

  :global .react-datepicker__calendar-icon {
    width: auto;
    height: auto;
  }

  :global .react-datepicker__input-container {
    @include flex-container(flex-start, flex-start, row-reverse);
  }

  :global .react-datepicker__view-calendar-icon input {
    padding: 0 0 0 13px;
    @include font(15, primary, 400);
  }

  :global .react-datepicker {
    @include font(14, primary);
    border: 1px solid $color-border;
    color: $color-primary;
  }

  :global .react-datepicker__year-wrapper {
    max-width: 272px;
  }

  :global .react-datepicker__header {
    background-color: $color-lavender-50;
  }

  :global .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before,
  :global .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
    border-bottom-color: $color-border;
  }

  :global .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before {
    border-top-color: $color-border;
  }

  :global .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle,
  :global .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
    margin-left: -4px;
  }

  :global .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before,
  :global .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before,
  :global .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::after,
  :global .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
    left: -4px;
  }
}

.error {
  @include font(13, primary, 500);
  margin-top: 3px;
  color: $color-error;
}
