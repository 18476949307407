@import 'theme/common-style';

.root {
  @include flex-container(flex-start, flex-start, column nowrap, false, 32px);
  width: 528px;
}

.sectionWrapper {
  @include flex-container(space-between, flex-start, row nowrap, false, 48px);
  width: 100%;
  position: relative;
}

.error {
  color: $color-error;
}

.loaderWrapper {
  width: 100%;
  margin-top: 100px;
  @include flex-center;
}
