@import 'theme/common-style';

.details {
  @include flex-container(flex-start, flex-start, column);
  width: 400px;
}

.accountDetails {
  margin-bottom: 24px;
}

.accountDetails dl {
  row-gap: 20px;

  dt {
    width: 150px;
  }
  dd {
    width: 290px;
  }
}

.accordionItemLabel {
  @include flex-container(space-between, center, $gap: 1em);
  @include font(12, secondary, 600);
  width: 100%;
}
