@import 'theme/common-style';

.inputs {
  @include flex-container(space-between, center);
  margin-top: 10px;
}

.inputWrapper {
  position: relative;

  .dollar {
    position: absolute;
    top: 10px;
    left: 18px;
    @include font(15, primary, 500);
  }

  .moreThan {
    position: absolute;
    top: 3px;
    left: 90px;
    @include font(23);
  }

  .input {
    @include font(15, primary, 500);
    height: 40px;
    width: 130px;
    background-color: $color-lavender-50;
    color: $color-primary;
    border-radius: 5px;
    padding-left: 28px;
    padding-right: 18px;
    border: none;

    &:focus,
    &:active {
      outline: 1px solid $color-border;
      background-color: $color-white;
    }

    ::placeholder {
      color: $color-gray-placeholder;
      @include font(15);
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-text-fill-color: $color-black !important;
    }
  }
}

.progressWrapper {
  position: relative;

  .progress {
    height: 3px;
    background-color: $color-primary;
    position: absolute;
    top: 15px;
  }
}

.line {
  position: relative;
  padding: 16px 0;

  .rangeInput {
    position: absolute;
    top: 16px;
    -webkit-appearance: none;
    appearance: none;
    pointer-events: none;
    width: 100%;
    height: 1px;
    background-color: $color-primary;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      pointer-events: auto;
      background-color: $color-primary;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      cursor: pointer;
    }

    // mozilla
    &::-moz-range-thumb {
      -webkit-appearance: none;
      appearance: none;
      pointer-events: auto;
      background-color: $color-primary;
      height: 16px;
      width: 16px;
      border: none;
      border-radius: 50%;
      cursor: pointer;
    }
  }
}
