@import 'theme/common-style';

.header {
  margin-top: 36px;
}

.content {
  @include font(14, $height: 20);
  margin-top: 24px;
  max-width: 100%;
  word-wrap: break-word;
}
