@import 'theme/common-style';

.root {
  @include flex-container(flex-start, flex-start, column nowrap, false, 36px);
  width: 100%;
  max-width: fit-content;
  margin-bottom: 40px;
}

.row {
  width: 100%;
  @include flex-container(space-between, center, row nowrap, false, 15px);
  margin-bottom: 10px;
}

.transactionId {
  @include font(14, secondary, 400, 20);
}

.for {
  @include flex-container(flex-start, center, row nowrap, false, 8px);
  @include font(14, primary, 400, 20);
  margin-bottom: 34px;
}

.buttonWrapper {
  @include flex-container(flex-start, center, row wrap, false, 24px);
}

.status {
  @include flex-container(flex-start, center, row nowrap, false, 8px);
  color: $color-error;
  @include font(14, primary, 600, 20px);
}
