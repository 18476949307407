@import 'src/theme/common-style';

.root {
  @include flex-container(center, center, column);
  width: 100%;
}

.rejectedInfoContainer {
  @include flex-center;
  @include font(15, primary, 700);
  width: 100%;
  height: 24px;
  background-color: $color-redrose;
  color: $color-white;
}

.toolbar {
  @include flex-container(space-between);
  width: 100%;
  padding: 0px 5px;
  border: 1px solid $color-french-gray;
  height: 26.5px;

  .zoomSelect {
    width: 140px;
  }

  &IconsContainer {
    @include flex-center;
    gap: 4px;
  }

  &Icon {
    cursor: pointer;
    width: 20px;
    height: 20px;

    &:hover {
      background-color: $color-french-gray;
    }
  }
}

.iframeContainer {
  @include flex-container;
  width: 100%;
  min-height: 650px;
  padding: 35px 30px;
  border: 1px solid $color-french-gray;
  background-color: $color-lavender-50;
}

.pdfView {
  @include flex-center;
  width: 100%;
}

