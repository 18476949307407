@import 'theme/common-style';

.details {
  @include flex-container(flex-start, flex-start, column);
  width: 410px;
  margin-bottom: 40px;
}

.kycDetails dl {
  row-gap: 20px;
}

.verifiedId {
  color: $color-success;
}

.notVerifiedId {
  color: $color-warning;
}

.rejectedId {
  color: $color-error;
}

.kycVerificationInfo {
  width: 100%;
  @include font(13, primary, 400, 20);

  margin-bottom: 24px;
}

.accordionItemLabel {
  @include flex-container(space-between, center, $gap: 1em);
  @include font(12, secondary, 600);
  width: 100%;
}
