@import 'src/theme/common-style';

.root {
  @include scrollable-table(1024px);
}

.table {
  table-layout: fixed;
  width: 100%;
}

.rowTable {
  cursor: pointer;
  width: 100%;

  &:hover {
    background-color: $color-lavender-30;
  }
}

td {
  padding: 12px 10px;
}

.imageTd {
  width: 84px;

  .img {
    background-color: $color-white;
    object-fit: contain;
    width: 64px;
    height: 64px;
  }
}

.userTd {
  @include flex-container(center, flex-start, column nowrap, false, 16px);
  height: 100%;

  &.asList {
    margin-top: 36px;
  }
}

.userCard {
  @include flex-container(flex-start, center, row nowrap, false, 16px);
  width: 100%;
}

.author {
  width: 88%;
}

.contactInfo {
  @include font(13);
  min-width: fit-content;
  width: 48px;
}

.statusTd {
  width: 170px;
}

.status {
  @include flex-container(flex-start, center, row wrap, false, 14px);
  @include font(13, primary, 600);
}
