@import 'theme/common-style';

.root {
  width: 100%;
}

.itemWrapper {
  margin-bottom: 40px;
}

.rowWrapper {
  width: 100%;
  @include flex-container(space-between, center);
  margin-bottom: 24px;
  cursor: pointer;
}

.buttonsWrapper {
  @include flex-container(flex-start, center, row wrap, false, 24px);
}
