@import 'theme/common-style';

.root {
  color: $color-french-gray;
  @include flex-center;
  @include font(17, primary, 500);
  height: 64px;
  width: 64px;
  background-color: $color-white;

  &.listSmall {
    @include font(12, primary, 500);
  }

  &.listMedium {
    @include font(17, primary, 500);
  }

  &.listBig {
    @include font(22, primary, 500);
  }

  &.gridSmall {
    @include font(36, primary, 500);
  }

  &.gridMedium {
    @include font(45, primary, 500);
  }

  &.gridBig {
    @include font(50, primary, 500);
  }
}
