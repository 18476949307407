@import 'theme/common-style';

.root {
  @include font(13, primary, 400, 16);
  margin-bottom: 24px;

  button {
    @include font(13, primary, 700, 16);
    text-decoration: underline;
  }
}
