@import 'theme/common-style';

.root {
  h2 {
    @include font(27, secondary, 400, 32);
    margin-bottom: 32px;
  }

  .items {
    display: grid;
    grid-template-columns: 300px 50px minmax(55px, auto) minmax(55px, auto);
    width: fit-content;
    min-width: $billing-column;
  }

  .heading {
    @include font(11, primary, 400, 20);
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 1px solid $color-primary;

    &.headingDesc {
      padding-right: 16px;
    }

    &.headingQuantity {
      padding-right: 16px;
      text-align: center;
    }

    &.headingUnitPrice {
      padding-right: 16px;
      text-align: end;
    }

    &.headingAmount {
      text-align: end;
    }
  }

  .desc {
    @include font(14, primary, 700, 20);
    padding-right: 16px;
    padding-top: 16px;
  }

  .quantity {
    @include font(14, primary, 400, 20);
    padding-right: 16px;
    padding-top: 16px;
    text-align: center;
  }

  .unitPrice {
    @include font(14, primary, 400, 20);
    padding-right: 16px;
    padding-top: 16px;
    text-align: end;
  }

  .amount {
    @include font(14, primary, 700, 20);
    padding-top: 16px;
    text-align: end;
  }
}
