@import 'src/theme/common-style';

.section {
  @include flex-container(space-between, center, row nowrap, false, 48px);
  width: 100%;
}

.content {
  position: relative;
  width: 100%;

  .spinnerWrapper {
    position: absolute;
    top: 0;
    right: 50%;
  }
}

.assetsList {
  @include flex-container(flex-start, center, row wrap, false, 24px);

  &.hideList {
    opacity: 0;
  }

  .remove {
    height: 22px;
    width: 22px;
    cursor: pointer;
  }
}

.asset{
  @include flex-container(space-between, center, row wrap, false, 24px);
  width: 100%;
}

.assetDetails {
  @include flex-container(space-start, center, row wrap, false, 12px);
  &.left {
    @include font(13, primary, 600, 20);
  }

  &.right {
    @include font(13, primary, 400, 20);
  }
}

.imageWrapper {
  position: relative;

  &:hover {
    .remove {
      @include flex-center;
    }
  }

  .remove {
    display: none;
    position: absolute;
    top: -8px;
    right: -8px;
    height: 22px;
    width: 22px;
    cursor: pointer;
    border-radius: 50%;
    background-color: $color-white;
    box-shadow: $box-shadow;
    color: $color-gray-placeholder;

    svg {
      height: 16px;
      width: 16px;
    }
  }

  .image {
    background-color: $color-white;
    object-fit: contain;
    width: 88px;
    height: 88px;
  }
}

.instruction {
  @include font(13, primary, 400, 16);
}
