@import 'src/theme/common-style';

.root {
  height: 100vh;
  width: $sidebar-width;
  font-family: $font-secondary;
  @include flex-container(space-between, initial, column);
  @include vertical-scrollbar;
  position: fixed;
  background-color: $color-white;
  z-index: 3;

  // TODO uncomment when ready
  // .pic1 {
  //   height: 48px;
  //   width: 48px;
  //   border-radius: 5px;
  //   object-fit: cover;
  //   margin: 36px 0 41px 24px;
  // }

  // TODO remove when .pic1 is ready
  .imagePlaceholder {
    height: 48px;
    width: 48px;
    border-radius: 5px;
    margin: 36px 0 41px 24px;
    background-color: $color-lavender;
  }

  .items {
    padding: 24px;

    .line {
      width: 100%;
      height: 0;
      border-bottom: 1px solid $color-french-gray;
      margin: 26px 0 28px 0;
    }
  }

  .userSection {
    width: 100%;
    border-top: 1px solid $color-french-gray;
    padding: 18px 24px;
    @include flex-container;
    position: sticky;
    bottom: 0;
    background-color: $color-white;
  }
}
