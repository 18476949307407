@import 'theme/common-style';

.root {
  .background {
    background-image: $gradient-lavender-white-180;
    background-size: auto 138px;
    background-repeat: no-repeat;
    padding: 48px;
  }
}
