@import 'src/theme/common-style';

.root {
  width: 100%;
  min-width: 800px;
  height: 100%;
  white-space: nowrap;
  table-layout: fixed;
  border-spacing: 0;

  .row {
    cursor: pointer;

    &:hover {
      background-color: $color-lavender-30;

      // uncomment when ready
      // .hiddenBtns {
      //   display: flex;
      //   gap: 12px;
      // }
    }
  }

  // uncomment when ready
  // .hiddenBtns {
  //   gap: 14px;
  //   display: none;
  // }

  td {
    padding: 12px 10px;
    width: 100%;

    &.isSmall {
      padding: 10px 10px;
    }

    &.checkboxTd {
      width: 36px;
    }

    // uncomment when ready
    // &.actionsTd {
    //   width: 118px;
    // }

    &:has([id='image']:only-child),
    &:has([id='image']):has([id='visibilityIcon']),
    &:has([id='image']):has([id='statusIcon']) {
      &.isSmall {
        width: 68px;
      }
      &.isMedium {
        width: 88px;
      }
      &.isBig {
        width: 112px;
      }
    }

    &:has([id='visibilityIcon']:only-child),
    &:has([id='statusIcon']:only-child),
    &:has([id='visibilityIcon']):has([id='statusIcon']) {
      width: 36px;
    }

    &.tags {
      width: 80%;
    }
  }

  // uncomment when ready
  // .actionBtns {
  //   @include flex-container(space-between, center, row-reverse, false, 14px);
  //   width: 100%;
  // }
}
