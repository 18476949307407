@import 'src/theme/common-style';

.inputGroup {
  @include flex-center;
  gap: 24px;

  .input {
    appearance: none;
    height: 16px;
    width: 16px;
    min-width: 16px;
    border: 1px solid $color-primary;

    border-radius: 50%;

    display: grid;
    place-content: center;

    &:hover {
      cursor: pointer;
    }

    &:focus,
    &:hover {
      outline: 1px solid $color-border;
    }

    &::before {
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 50%;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 8px 8px $color-primary;
    }

    &:checked::before {
      transform: scale(1);
    }
  }

  .label {
    @include font(15, primary, 500, 19);
    cursor: pointer;
  }
}
