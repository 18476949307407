@import 'theme/common-style';

.root {
  @include flex-container(flex-start, flex-start, column nowrap, false, 32px);
  @include font(14);
  max-width: 527px;
}

.label {
  margin-right: 8px;
}
