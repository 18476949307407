@import 'theme/common-style';

.header {
  @include flex-container(space-between, center);
  margin-bottom: 28px;
}

.splitCommissionRowWrapper {
  position: relative;

  .error {
    position: absolute;
    bottom: -12px;
    right: 0;
    color: $color-error;
    @include font(13, primary);
  }
}

.splitCommissionRow {
  @include flex-container(flex-start, center, $gap: 24px);
  margin-bottom: 28px;
}

.input {
  width: 100px;
  margin-bottom: 0;
  margin-left: auto;
  flex-shrink: 0;

  input {
    @include hide-spin-button-on-number-input;
    text-align: right;
  }
}

.removeIcon {
  color: $color-primary;
  flex-shrink: 0;
  cursor: pointer;

  &.disabled {
    color: $color-primary-60;
    cursor: default;
  }
}
