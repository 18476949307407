@import 'theme/common-style';

.root {
  min-height: 100vh;
  width: 100%;
  color: $color-primary;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('/theme/assets/images/bg-desktop.png');
  background-position: top;
  @include flex-container(flex-start, center, column, false, 47px);
  padding: 36px;

  @include screen-xl {
    background-image: url('/theme/assets/images/bg-desktop-wide.png');
  }

  .innerWrapper {
    width: 528px;

    @include screen-max(600px) {
      width: calc(100vw - 72px);
    }

    .form {
      @include flex-container(center, stretch, column, false, 10px);
      width: 100%;
    }

    .submitBtn {
      margin-top: 33px;
    }

    .submitButtonContainerBank {
      margin-top: 28px;
    }

    .formSubtitle {
      @include font(27, secondary, 400, 32);
      margin: 40px 0 24px 0;
    }

    .changeOrgType {
      @include font(15, primary, 400, 20);
      @include flex-container(center, center, column nowrap);
      margin-top: 32px;
    }

    .verificationFeeTitle {
      @include font(16, primary, 700);
      margin: 20px 0;
    }

    .verificationFeeAmount {
      @include font(29, primary, 600);
      margin-bottom: 100px;
    }

    .succeededQuestion {
      @include font(29, primary, 600);
      margin-bottom: 16px;
    }

    .centerContent {
      text-align: center;
    }

    .textButtonAsSecond {
      margin-top: 34px;
    }

    .errorIbanInput {
      margin-bottom: 36px;
    }
  }
}
