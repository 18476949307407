@import 'theme/common-style';

.root {
  padding: 36px 48px;
  color: $color-primary;
}

.formContainer {
  margin-top: 36px;
  width: 430px;
}

.recoveryCodes {
  @include font(14);
  margin-top: 32px;
}

.recoveryCodesButton {
  margin-left: 0.3em;
}
