@import 'theme/common-style';

.transactionSitesAddresses {
  @include flex-container(space-between, $gap: 180px);
  margin: 32px auto 41px 0;
}

.addAccount {
  @include flex-container(center, center, column, $gap: 24px);
  width: 100%;
}

.escrowDetailsWithEditButton {
  @include flex-container(flex-start, flex-start, $gap: 62px);

  .editButton {
    padding-top: 3px;
    text-decoration: none;
  }
}

.markAsPaidButton {
  margin-top: 24px;
}
