@import 'theme/common-style';

.root {
  display: flex;
  flex: 1;
  gap: 8px;
  border: 1px solid $color-primary;
  border-radius: 5px;
  cursor: pointer;
  padding: 16px 16px 32px 24px;

  &.checked {
    cursor: initial;
    background-color: $color-lavender-50;
    border-color: $color-lavender-50;

    .customInput {
      &::after {
        content: '';
      }
    }
  }
}

.radioButton {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.customInput {
  position: relative;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  border-radius: 100%;
  border: 1px solid $color-primary;

  &::after {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    border-radius: inherit;
    background-color: $color-primary;
  }
}

.title {
  @include font(15, primary, 700, 20);
  padding-top: 16px;
}

.description {
  @include font(13, primary, 400, 20);
  margin-top: 8px;
  margin-bottom: auto;
}
