@import 'src/theme/common-style';

.root {
  @include scrollable-table(670px);
}

.table {
  min-width: 1100px;
  table-layout: fixed;
  width: 100%;
}

.stateIcon {
  width: 250px;
}

.rowTable {
  width: 100%;
  @include font(13);
  cursor: pointer;
  position: relative;
  z-index: 0;

  &:hover {
    background-color: $color-lavender-30;
  }

  td {
    padding: 12px 10px 16px 10px;
    position: relative;

    &.invoiceStateTd {
      padding-left: 0px;
      @include flex-container(flex-start, center);
      width: 300px;
    }

    &.transactionSiteTd {
      width: 300px;

      p {
        font-size: 14px;
      }

      .personWrapper {
        @include flex-container(flex-start, center, row nowrap, false, 8px);
      }
    }

    &.bankNoteTd {
      .bankNoteContainer {
        @include flex-center;
        width: 270px;

        span {
          @include font(13, primary, 400);
          color: $color-primary;
          width: 254px;
          font-style: italic;
          margin-left: 13px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }

    &.priceTd {
      margin-left: 30px;
      width: 230px;

      .priceWrapper {
        @include flex-container(flex-end, flex-end, $gap: 8px);
        @include font(15, primary, 700);
        color: $color-primary;
      }
    }
  }
}

.rejectedInvoiceRow {
  &::before {
    content: '';
    position: absolute;
    top: 47%;
    left: 0;
    width: 100%;
    height: 32px;
    background-color: $color-redrose-24;
    z-index: -1;
    transform: translateY(-50%);
    display: block;
    pointer-events: none;
  }

  &:hover {
    background-color: $color-redrose-24;
  }
}
