@import 'theme/common-style';
.root {
  @include flex-container(flex-start, flex-start, column nowrap, false, 22px);
}
.wrapper {
  border-bottom: 1px solid $color-french-gray;
  @include flex-container(flex-start, flex-start, column nowrap, false, 8px);
  padding-bottom: 32px;
  width: 100%;
}

.picture {
  align-self: center;
}
.form {
  width: 100%;
  @include flex-container(flex-start, flex-start, column nowrap, false, 6px);
}

.button {
  margin-top: 33px;
}
