@import 'theme/common-style';

.root {
  width: 100%;
  position: relative;
}

.label {
  @include font(13, primary, 400);
  display: block;
  color: $color-primary;
  margin-bottom: 6px;
}

.textarea {
  width: 100%;
  height: 160px;
  resize: none;
  overflow: hidden;
  outline: none;
  @include font(17, primary, 500);
  background-color: $color-lavender-50;
  color: $color-primary;
  border-radius: 5px;
  padding: 16.5px;
  border: none;
  @include vertical-scrollbar($color-lavender-30, 20px, 7px, $color-white, $color-lavender-30);

  &:focus,
  &:active {
    outline: 1px solid $color-border;
    background-color: $color-white;
  }

  &.withError {
    outline: 1px solid $color-error;
    background-color: $color-white;
  }
}

.error {
  @include font(13, primary, 500);
  position: absolute;
  color: $color-error;
  top: 100%;
  left: 0;
}
