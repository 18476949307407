@import 'src/theme/common-style';

.root {
  position: relative;
  margin: 0 2px;
  width: 100%;
}

.cutContentWrapper {
  @include text-overflow-ellipsis;
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
}

.uncutContentWrapper {
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
  position: absolute;
  top: 0;
  z-index: 1;
  width: max-content;
  background-color: $color-lavender-30;
  padding-right: 8px;
  overflow: hidden;
}

.nameYear {
  @include font(14, primary, 400, 20);

  span {
    font-style: italic;
  }
}

.normal {
  @include font(14, primary, 400, 20);
}

.normalSecondary {
  @include font(14, secondary, 400, 20);
}

.bold {
  @include font(15, primary, 700, 20);
}

.boldRed {
  @include font(15, primary, 700, 20);
  color: $color-redrose;
}

.italic {
  @include font(14, primary, 400, 20);
  font-style: italic;
}

.semibold {
  @include font(15, primary, 600, 20);
}

.userWithAvatarSemibold {
  @include font(15, primary, 600, 20);
  width: calc(100% - 108px);
}
