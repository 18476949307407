@import 'theme/common-style';

.consignee {
  padding: 0 48px 34px;
}

.formWrapper {
  background-image: $gradient-lavender-white-180;
  background-size: auto 138px;
  background-repeat: no-repeat;
}

.form {
  padding: 40px 48px;
  @include flex-container(flex-start, flex-start, column nowrap, false, 25px);
}

.innerWrapper {
  padding-top: 32px;
  border-top: 1px solid $color-french-gray;
  @include flex-container(flex-start, flex-start, column nowrap, false, 12px);
  width: 100%;

  input[type='number'] {
    text-align: end;
  }

  input {
    @include hide-spin-button-on-number-input;
  }
}
