@import 'src/theme/common-style';

.root {
  width: 100%;
  position: relative;
  background-color: inherit;

  .uncutName {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}
