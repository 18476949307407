@import 'src/theme/common-style';

.root {
  position: relative;
  width: 100%;
  @include font(13);

  .spinnerWrapper {
    position: absolute;
    top: 0;
    right: 50%;
  }
}

.addDocumentWrapper {
  @include flex-container(flex-start, center);

  button {
    font-size: 14px !important;
    font-weight: 400 !important;
    font-family: $font-secondary !important;
  }
}

.detailsWrapper {
  @include flex-container(flex-start, flex-start, column);
  width: 100%;

  .detailsAndRemoveContainer {
    @include flex-container;
    width: 100%;
  }

  .details {
    width: 100%;
    height: 24px;
    @include flex-container(space-between, center);
    background-color: $color-lavender-30;

    &.isError {
      background-color: $color-redrose-24;
    }

    .downloadWrapper {
      @include font(13, primary, 600);

      .download {
        @include flex-container(flex-start, center);
        cursor: pointer;

        svg {
          min-width: 24px;
        }

        .documentName {
          margin-left: 3px;
          width: 240px;
        }
      }
    }
  }

  .remove {
    cursor: pointer;
    display: inline-flex;
    margin-left: 12px;
  }

  .rejected {
    @include flex-container(flex-start, flex-start, column);
    height: 100%;
    width: 100%;
    margin-top: 5px;

    .rejectedInfo {
      @include flex-container(flex-start, center);
      width: 175px;
      color: $color-redrose;
      cursor: pointer;
    }

    .menuIcon {
      height: 18px;
      width: 24px;
      margin-left: 2px;
      transform: rotate(90deg);

      svg path {
        fill: $color-redrose;
      }

      &Open {
        transform: rotate(270deg);
        margin-left: -5px;
      }
    }
  }

  .bankNoteContent {
    @include flex-container(flex-start, flex-start, column);
    width: 100%;
    height: 150px;
    margin: 13px 0;
    color: $color-primary;

    p {
      @include font(13, primary, 400);
      margin-bottom: 6px;
      color: $color-primary;
    }

    section {
      @include flex-container;
      width: 100%;
      height: 112px;
      overflow-y: auto;
      @include vertical-scrollbar;
      padding: 13px 18.5px 18px 10px;
      border-radius: 5px;
      background: $color-lavender-30;
      @include font(15, primary, 500, 21);
      font-style: italic;
      color: $color-primary;
    }
  }
}

.showBg {
  background-color: $color-lavender-30;
}
