@import 'theme/common-style';

$transaction-layout-gap: 40px;

.consignmentData {
  @include flex-container;
  gap: $transaction-layout-gap;

  margin: 48px -48px 0;
  padding: 36px 48px 36px 0;
  background-color: $color-seasalt;
  min-width: 900px;
}

.consignmentDetails {
  width: calc((100% - $transaction-layout-gap) / 2);
  padding-left: 48px;
}

.consignee {
  @include flex-container(flex-start, flex-start, column nowrap, false, 24px);
}
