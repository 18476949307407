@import 'src/theme/common-style';

.root {
  @include flex-container(flex-start, center, row, false, 28px);

  .wrapper {
    @include flex-container(flex-start, center);

    button:disabled {
      opacity: 0.2;
      cursor: default;
    }

    .arrowBtn {
      @include flex-center;
    }

    .pageBtn {
      @include font(11, primary, 400);
      color: $color-primary;
      width: 24px;

      &.active {
        @include font(11, primary, 600);
      }
    }
  }

  .input {
    @include hide-spin-button-on-number-input;
    background-color: $color-lavender-50;
    color: $color-primary;
    border: none;
    width: 32px;
    height: 32px;
    border-radius: 5px;
    margin-left: 8px;
    text-align: center;
  }

  .text {
    @include font(11, primary, 400, 20);
  }
}
