@import './common-colors.scss';
@import './common-constants.scss';
@import './common-flex.scss';
@import './common-font.scss';
@import './common-forms.scss';
@import './common-responsive.scss';
@import './common-scrollbar.scss';
@import './common-table.scss';

@mixin page {
  min-height: calc(100vh - 72px);
  position: relative;
}

@mixin card {
  padding: 24px;
  background-color: $color-white;
  box-shadow: $box-shadow;
}

@mixin container {
  margin: 0 auto;
  max-width: 1200px;

  @include screen-xs {
    max-width: 90%;
  }
}

@mixin modal-form {
  > div:last-child {
    @include flex-container($justify-content: flex-end, $gap: 10px);
    margin-top: 20px;
  }
}

@mixin text-overflow-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin text-line-clamp($lineNumbers: 1) {
  display: -webkit-box;
  -webkit-line-clamp: $lineNumbers;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
}

@mixin hide-spin-button-on-number-input {
  &[type='number'] {
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    -moz-appearance: textfield;
  }
}
