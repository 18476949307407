@mixin invoice-table-row {
  @include flex-container(flex-start, center);
  width: 100%;
  @include font(13);

  &:hover {
    background-color: $color-lavender-30;
  }
  td {
    padding: 12px 10px 16px 10px;

    &.checkboxTd {
      width: 40px;
      padding-left: 0px;
    }

    &.invoiceSenderTd {
      width: 308px;
      padding-left: 0px;

      p {
        font-size: 14px;
      }

      .personWrapper {
        @include flex-container(flex-start, center, row nowrap, false, 16px);
      }
    }

    &.invoiceStateTd {
      @include flex-center;
      margin-left: 175px;
    }

    &.invoiceDetailsTd {
      margin-left: 11px;
      width: 567px;
    }

    &.invoiceAmountTd {
      padding-left: 0px;

      .priceWrapper {
        text-align: end;
        @include font(15, primary, 700);
        padding-left: 0px;
      }
    }
  }
}

@mixin scrollable-table($max-width: $table-max-height) {
  @include screen-sm-plus {
    width: max($table-max-width, $max-width);
    max-height: $table-max-height;
    @include horizontal-scrollbar(transparent, 3px, 0, transparent, transparent);
    @include vertical-scrollbar(transparent, 3px, 0, transparent, transparent);

    &:hover {
      @include horizontal-scrollbar($color-white, 3px, 0);
      @include vertical-scrollbar($color-white, 3px, 0);
    }
  }
}
