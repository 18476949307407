@import 'theme/common-style';

.root {
  border: 1px solid $color-border-dark;
  border-radius: 5px;
  padding: 13px 16px;
  max-width: $billing-column;
  @include flex-container(space-between, center, row wrap, false, 24px);
}

.column {
  @include flex-container(flex-start, center, row nowrap, false, 23px);
  min-width: 211px;

  &.noPaymentMethod {
    @include flex-container(center, flex-end);
  }
}

.image {
  width: 64px;
  height: 42px;
  border-radius: 6px;
}

.card {
  @include font(15, primary, 700, 20px);
  @include flex-container(center, center, row wrap, false, 6px);
}
.status {
  @include font(14, primary, 500, 20px);
  color: $color-redrose;

  &.active {
    color: $color-jade;
  }
}
