@import 'src/theme/common-style';

.root {
  min-width: 1100px;
  table-layout: fixed;
  width: 100%;
}

.stateIcon {
  width: 250px;
}

.rowTable {
  cursor: pointer;
  @include flex-container(flex-start, center);
  width: 100%;
  @include font(13);

  &:hover {
    background-color: $color-lavender-30;
  }

  td {
    padding: 12px 10px 16px 10px;

    &.invoiceStateTd {
      padding-left: 0px;
      width: 250px;
    }

    &.firstLeftMargin {
      margin-left: 10.5px;
    }

    &.transactionSiteTd {
      width: 290px;
    }

    &.invoiceAmountTd {
      width: 260px;

      .priceWrapper {
        @include flex-container(flex-end, flex-end, $gap: 8px);
        @include font(15, primary, 700);
        color: $color-primary;
      }
    }
  }
}

.rowTableNoData {
  @include flex-center;
}
