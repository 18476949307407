@import 'theme/common-style';

$price-column: calc($billing-column - 300px - 100px - 20px - 3 * 16px);

.root {
  h2 {
    @include font(27, secondary, 400, 32);
    margin-bottom: 20px;
  }

  .row {
    width: 100%;
    @include flex-container(space-between, center);
    padding: 12px 0;
  }

  .items {
    display: grid;
    grid-template-columns: 300px 100px minmax($price-column, auto) 20px;
    gap: 16px;
    align-items: center;
    align-content: start;
    width: fit-content;
  }

  .numberAndDate {
    .number {
      @include font(14, primary, 700, 20);
    }

    .date {
      @include font(14, primary, 400, 18);
    }
  }

  .isPaid {
    width: fit-content;
  }

  .price {
    @include font(18, primary, 700, 20);
  }

  .downloadIcon {
    a {
      display: block;
      width: fit-content;

      svg {
        display: block;
      }
    }
  }
}
