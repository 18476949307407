@import 'theme/common-style';

.root {
  width: 100%;

  .btnMore {
    @include flex-container(center, center, row-reverse);
    text-decoration: none;
    margin-top: 16px;
  }
}
