@import 'theme/common-style';

.root {
  @include flex-container(space-between, flex-start);
  margin-bottom: 40px;

  .event {
    @include flex-container($flex-flow: column);
    @include font(13);
    margin-top: 14px;
  }

  .rightColumn {
    .event {
      text-align: right;
    }
  }

  .date {
    @include font(14, primary, 700, 20);
    margin-top: 8px;
  }

  .rejected {
    @include flex-container(flex-end, center);
    margin-top: -2.5px;
    margin-bottom: -2px;
  }
}
