@import 'theme/common-style';

.root {
  @include flex-container(flex-start, flex-start, column nowrap, false, 8px);
  width: 100%;

  input {
    @include hide-spin-button-on-number-input;
  }
}

.sectionWrapper {
  @include flex-container(space-between, flex-start, row nowrap, false, 48px);
  width: 100%;
  position: relative;
}
