@import 'src/theme/common-style';

.root {
  .actions {
    @include flex-container(flex-start, stretch, column nowrap, false, 16px);
  }
}

.otherApprovalWrapper {
  max-height: 70vh;
  @include vertical-scrollbar;

  .otherApproval {
    @include flex-container(space-between, center);
    height: 64px;
    width: 100%;
    margin-bottom: 16px;

    .contactInfo {
      @include flex-container(flex-start, center, row nowrap, false, 24px);

      .nameAndTitle {
        height: 64px;
        @include flex-container(flex-start, center);
      }
    }

    .approvalInfo {
      @include flex-container(flex-start, center, row nowrap, false, 12px);
      @include font(14, primary, 600, 20);
      width: 144px;
    }
  }
}
