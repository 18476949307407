@import 'src/theme/common-style';

.root {
  @include flex-container(flex-start, flex-start, column);
  width: 100%;
  margin-top: 41px;
}

.title {
  @include font(27, secondary, 400, 32);
  margin-bottom: 30px;
}

.invoicesTable {
  border-top: 1px solid $color-primary;
  padding-top: 34px;
}
