@import 'theme/common-style';

.sectionHeader {
  margin-top: 12px;
}

.authAppLinks {
  @include flex-container(flex-start, flex-start, column);
  margin-top: 8px;
  margin-left: 24px;
  margin-bottom: 32px;
}

.authAppLink {
  @include font(14, primary, 600, 20);
  text-decoration: underline;
  position: relative;
  padding-left: 16px;

  &::before {
    content: '•';
    position: absolute;
    margin-left: -16px;
  }
}

.QRCodeContainer {
  @include flex-container(flex-start, center, column);
  margin-top: 40px;
  margin-bottom: 48px;

  .QRSecret {
    @include flex-container(center, center);
    gap: 1px;
    margin-bottom: 12px;
    height: 14px;
    text-transform: uppercase;

    &Chunk {
      @include font(14, secondary, 600, 14);
      letter-spacing: 1px;
    }
  }

  .QRCode {
    height: 180px;
  }
}

.backButtonContainer {
  display: flex;
}

.formContainer {
  width: 528px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.paragraph {
  @include font(14, primary, 400, 20);
  margin-top: 16px;
}
