@import 'theme/common-style';

.root {
  margin-top: 36px;
}

.buyersList {
  @include flex-container(flex-start, flex-start, column nowrap, false, 16px);
}

.buyer {
  @include flex-container(space-between, center, row nowrap, false, 24px);
  width: 100%;
}

.shareInput {
  margin-bottom: 0;
  width: 100px;

  input {
    min-width: 100px;
    text-align: end;

    @include hide-spin-button-on-number-input;
  }
}

.header {
  width: 100%;
  @include flex-container(space-between, center, row nowrap, false, 6px);
  margin-bottom: 28px;
}
