@import 'theme/common-style';

.root {
  @include flex-container(flex-start, center);
  position: relative;
  border: 1px solid $color-primary;
  border-radius: 5px;
  padding: 7px 16px 7px 5px;
  word-break: break-all;
  background-color: $color-white;
  opacity: 0.999; // hack to hide element background while dragging
}

.dragHandle {
  @include flex-container(flex-start, center);
  align-self: flex-start;
  cursor: grab;
}

.children {
  margin-left: 4px;
  flex-grow: 1;
}
