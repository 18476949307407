@mixin scrollbar($thumb-color, $track-color, $border-width, $background-color) {
  cursor: auto;
  scrollbar-color: $thumb-color $track-color;
  scrollbar-gutter: always;
  // padding: $border-width 0;
  // margin: calc(0px - $border-width) 0;

  &::-webkit-scrollbar {
    background-color: $track-color;
  }
  &::-webkit-scrollbar-track {
    background-color: $track-color;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $thumb-color;
    border-radius: 18px;
    border: $border-width $background-color solid;
  }
  &:-moz-scrollbar-thumb {
    border-radius: 18px;
    border: $border-width $background-color solid;
  }
}

@mixin vertical-scrollbar(
  $background-color: $color-white,
  $width: 20px,
  $border-width: 7px,
  $thumb-color: $color-lavender,
  $track-color: $color-white
) {
  @include scrollbar($thumb-color, $track-color, $border-width, $background-color);
  overflow-y: auto;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: $width;
  }
  &::-webkit-scrollbar-track {
    border-right: $border-width $background-color solid;
    border-left: $border-width $background-color solid;
  }
}

@mixin horizontal-scrollbar(
  $background-color: $color-white,
  $height: 20px,
  $border-width: 7px,
  $thumb-color: $color-lavender,
  $track-color: $color-white
) {
  @include scrollbar($thumb-color, $track-color, $border-width, $background-color);
  overflow-x: auto;
  scrollbar-height: thin;
  &::-webkit-scrollbar {
    height: $height;
  }
  &::-webkit-scrollbar-track {
    border-bottom: $border-width $background-color solid;
    border-top: $border-width $background-color solid;
  }
}

// IMPORTANT: if we want to use both vertical and horizontal scrollbars on one element, we must use it as '@include horizontal-scrollbar($color-white, 3px, 0)' and '@include vertical-scrollbar($color-white, 3px, 0)'
