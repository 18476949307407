@import 'src/theme/common-style';

.root {
  width: 100%;

  .item {
    @include flex-container(space-between);
    margin-top: 8px;
    margin-bottom: 8px;
    text-align: start;
  }

  .itemDesc {
    @include flex-container(flex-start, center);
    background-color: $color-white;

    &.activeItem {
      color: $color-vista-blue;

      svg g {
        fill: $color-vista-blue;
      }
    }
  }

  .icon {
    @include flex-container(center, center);
    height: 24px;
    width: 24px;
    margin-right: 16px;
  }

  .name {
    @include font(14, secondary, 400);
    letter-spacing: 0.78px;
    overflow: hidden;
    max-width: 175px;
    white-space: nowrap;

    &.isCut {
      max-width: 152px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.number {
  @include font(11, primary, 600);
  color: $color-redrose;
  background-color: $color-seasalt;
  border-radius: 4px;
  padding: 7px;
  height: 24px;
  width: 24px;
  text-align: center;
}

.subcategory {
  display: none;
  background-color: $color-seasalt;
  margin-left: -24px;
  padding: 0 24px;
  width: $sidebar-width;

  .itemDesc {
    @include flex-container(flex-start, center);
    background-color: $color-seasalt;

    &.activeItem {
      color: $color-vista-blue;
    }
  }

  &.isActive {
    display: flex;
  }
}
