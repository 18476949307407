@mixin account-form {
  .form {
    @include flex-container(flex-start, flex-start, column, $gap: 10px);
    padding: 52px 48px;

    .submitButtonContainer {
      @include flex-container(flex-start, flex-start, column);
      margin-top: 15px;
      width: 100%;
    }
  }
}
