@import 'src/theme/common-style';

.root {
  min-width: 1100px;
  table-layout: fixed;
  width: 100%;
}

.rowTable {
  @include flex-container(flex-start, flex-start);
  width: 100%;
  @include font(13);
  cursor: pointer;

  &:hover {
    background-color: $color-lavender-30;
  }

  td {
    padding: 12px 10px 16px 0px;

    &.idTd {
      width: 80px;
    }

    &.createdAtTd {
      width: 155px;
    }

    &.transactionSumTd {
      @include flex-container(flex-start, flex-start, $gap: 12px);
      width: 220px;

      .priceWrapper {
        text-align: end;
        @include font(15, primary, 700);
        padding-left: 0px;
      }
    }

    &.transactionSiteTd {
      @include flex-container(flex-start, flex-start, column, $gap: 10px);
      width: 270px;
    }

    &.progressTd {
      width: 160px;
    }

    &.transactionStatusTd {
      @include flex-container(flex-end);
      width: 200px;
      padding-right: 0;
      @include font(15, primary, 600, 20);
    }
  }
}

.rowTableNoData {
  @include flex-center;
}
