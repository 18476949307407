@import 'theme/common-style';

:root {
  --PhoneInput-color--focus: #a2a5d9;
}

.phoneInput {
  --PhoneInputCountryFlag-aspectRatio: 1.5;
  --PhoneInputCountryFlag-backgroundColor--loading: none;
  --PhoneInputCountryFlag-borderColor: none;
  --PhoneInputCountryFlag-borderWidth: 0;
  --PhoneInputCountryFlag-height: 16px;
  --PhoneInputCountrySelect-marginRight: 0;
  --PhoneInputCountrySelectArrow-borderWidth: 1px;
  --PhoneInputCountrySelectArrow-color: $color-primary;
  --PhoneInputCountrySelectArrow-marginLeft: 10px;
  --PhoneInputCountrySelectArrow-opacity: 1;
  --PhoneInputCountrySelectArrow-width: 7px;

  border: 1px solid transparent;
  border-radius: 5px;
  overflow: hidden;

  &.error {
    border-color: $color-redrose;
  }

  :global {
    .PhoneInputCountry {
      padding: 0 12px;
      background-color: $color-lavender-50;
    }

    .PhoneInputInput {
      outline: none;
      padding: 12px 16px;
      border: none;
      border-left: 1px solid $color-french-gray;
      background-color: $color-lavender-50;
    }
  }
}
