@import 'theme/common-style';

.root {
  position: relative;
  margin-bottom: 15px;
  width: 100%;
}

.label {
  @include font(13, primary, 400);
  display: block;
  color: $color-primary;
  margin-bottom: 6px;
}

.input {
  @include font(17, primary, 500);
  height: 40px;
  width: 100%;
  background-color: $color-lavender-50;
  color: $color-primary;
  border-radius: 5px;
  padding-right: 16.5px;
  text-indent: 16.5px;
  border: none;

  &:focus,
  &:active {
    outline: 1px solid $color-border;
    background-color: $color-white;
  }

  &:read-only {
    background-color: $color-french-gray;
    cursor: not-allowed;
  }

  &::placeholder {
    color: $color-gray-placeholder;
    @include font(15);
  }

  &Error {
    outline: 1px solid $color-error;
    background-color: $color-white;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-text-fill-color: $color-black !important;
  }
}

.endIcon {
  position: absolute;
  bottom: 0;
  right: 15px;
}

.error {
  @include font(13);
  position: absolute;
  color: $color-error;
  top: calc(100% + 3px);
  left: 0;
}
