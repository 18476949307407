@import 'theme/common-style';

.root {
  @include flex-container(flex-start, flex-start, column, $gap: 24px);
  max-width: 527px;
}

.ownerRow {
  @include flex-container(space-between, center, $gap: 24px);
  @include font(15, primary, 20);
  width: 100%;
}
