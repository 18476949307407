@import 'theme/common-style';

@mixin dot($color) {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: $color;
}

@mixin timeline($color) {
  width: 200px;
  height: 2px;
  background-color: $color;

  @include screen-md-minus {
    width: 150px;
  }
}

.root {
  @include flex-container;
  width: 487px;
}

.timelineItem {
  @include flex-container(flex-start, center);
}

.timelineItemWithState,
.timelineItemWithStateMiddle {
  @include flex-container(flex-start, center);
  position: relative;
}

.timelineDot {
  @include dot($color-primary);

  &Gray {
    @include dot($color-primary-13);
  }
}

.timelineLine {
  @include timeline($color-primary);

  &Gray {
    @include timeline($color-primary-13);
  }
}

.timelineText {
  @include flex-container(center, center, column, $gap: 4px);
  height: 71px;
  position: absolute;
  top: 6px;
  left: -30px;
  color: $color-primary;

  p {
    @include font(14, primary, 600, 20);
  }

  span {
    @include font(13, primary, 400, 20);
  }
}
