@import 'theme/common-style';

.stateTimeline {
  @include screen-md-minus {
    padding-left: 50px;
  }
}

.transactionId {
  margin-top: -15px;

  @include screen-md-minus {
    display: none;
  }
}

.transactionIdSmallScreen {
  margin: -30px 0 30px 0;

  @include screen-min($screen-lg-min) {
    display: none;
  }
}

.actionButtonsInfo {
  @include font(14, primary, 500);
  color: $color-redrose;
  margin-top: 15px;
  font-style: italic;
}

.transactionSitesAddresses {
  @include flex-container(space-between, $gap: 180px);
  margin: 56px auto 0 0;

  @include screen-md-minus {
    @include flex-container(flex-start, flex-start, column, $gap: 0);
    width: 100%;
  }
}

.accountDetailsSmallScreen {
  margin-top: 0;
  margin-bottom: 40px;

  @include screen-min($screen-lg-min) {
    display: none;
  }
}

.accountDetails {
  @include flex-container;

  @include screen-max($screen-lg-min) {
    display: none;
  }
}

.noAccountInfo {
  @include font(14, primary, 600);
  margin-top: 15px;

  span {
    @include font(13, primary);
    font-style: italic;
  }
}

.billingWrapper {
  margin-top: 41px;
}

.billingContainer {
  @include flex-container(flex-start, flex-start, column, $gap: 16px);
  width: 100%;
  border-bottom: 1px solid $color-french-gray;
  margin-top: 15.5px;
  padding-bottom: 15.5px;

  .artworkPriceWrapper {
    background-color: $color-lavender-30;
  }
}

.bankNoteSection {
  @include flex-container(flex-start, flex-start, column);
  width: 50%;
  margin-top: 56px;

  .sectionTitle {
    @include font(17, secondary, 500);
  }

  p {
    @include font(14, primary, 400, 20);
    margin-top: 16px;
  }

  section {
    @include font(14, primary);
    margin-top: 4px;
    text-align: left;
    font-style: italic;
  }
}
