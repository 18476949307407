@import 'theme/common-style';

.overlay {
  position: fixed;
  inset: 0;
  z-index: 999;
  overflow: auto;
}

.slidingPanel {
  width: 576px;
  height: 100%;
  background-color: $color-white;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99;
  box-shadow: $box-shadow;
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
  padding: 38px 0;
  color: $color-primary;
  @include vertical-scrollbar;

  @include screen-max(576px) {
    width: 100%;
  }

  &.open {
    transform: translateX(0);
  }
}

.header {
  @include flex-container(space-between, flex-end);
  padding: 0 48px 32px;
}

.actionBtns {
  @include flex-container(flex-start, flex-end, column, false, 28px);
  padding-bottom: 8px;
}

.resetBtn {
  @include font(12, secondary, 600);
}

.title {
  @include font(28, secondary, 400, 32);
}

.wrapper {
  padding: 0 48px;

  &.maxWidth {
    padding: 0;
  }
}
