@import 'src/theme/common-style';

.root {
    &.small {
      min-width: 64px;
      min-height: 64px;
    }
    &.big {
      min-width: 168px;
      min-height: 168px;
    }
  }