@import 'theme/common-style';

.root {
  h2 {
    @include flex-container(flex-start, center, $gap: 11px);
    margin-bottom: 22px;
  }

  .row {
    margin-bottom: 26px;

    .firstLineWrapper {
      @include flex-container(space-between, center);
      cursor: pointer;
      margin-bottom: 14px;

      .organizationWrapper {
        @include flex-container(flex-start, center);

        .direction {
          @include font(16, primary, 400, 20);
          margin-right: 10px;
        }

        .organizationName {
          margin-left: 12px;
          @include font(15, primary, 600, 20);
          @include text-overflow-ellipsis;
          max-width: 390px;
        }
      }

      &:hover {
        background-color: $color-lavender-30;
      }
    }
  }
}
