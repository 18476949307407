$screen-maxi-min: 1600px;

$screen-xl-max: $screen-maxi-min - 1;
$screen-xl-min: 1440px;

$screen-lg-max: $screen-xl-min - 1;
$screen-lg-min: 1100px;

$screen-md-max: $screen-lg-min - 1;
$screen-md-min: 992px;

$screen-sm-max: $screen-md-min - 1;
$screen-sm-min: 768px;

$screen-xs-max: $screen-sm-min - 1;
$screen-xs-min: 360px;

$screen-mini-max: $screen-xs-min - 1;

@mixin screen-range($min, $max) {
  @media only screen and (min-width: $min) and (max-width: $max) {
    @content;
  }
}

@mixin screen-minHeight($min) {
  @media only screen and (min-height: $min) {
    @content;
  }
}

@mixin screen-maxHeight($max) {
  @media only screen and (max-height: $max) {
    @content;
  }
}

@mixin screen-min($min) {
  @media only screen and (min-width: $min) {
    @content;
  }
}

@mixin screen-max($max) {
  @media only screen and (max-width: $max) {
    @content;
  }
}

@mixin screen-maxi {
  @include screen-min($screen-maxi-min) {
    @content;
  }
}

@mixin screen-xl {
  @include screen-range($screen-xl-min, $screen-xl-max) {
    @content;
  }
}

@mixin screen-lg {
  @include screen-range($screen-lg-min, $screen-lg-max) {
    @content;
  }
}

@mixin screen-md {
  @include screen-range($screen-md-min, $screen-md-max) {
    @content;
  }
}

@mixin screen-sm {
  @include screen-range($screen-sm-min, $screen-sm-max) {
    @content;
  }
}

@mixin screen-xs {
  @include screen-range($screen-xs-min, $screen-xs-max) {
    @content;
  }
}

@mixin screen-mini {
  @include screen-max($screen-mini-max) {
    @content;
  }
}

// small and larger
@mixin screen-sm-plus {
  @include screen-min($screen-sm-min) {
    @content;
  }
}

// small and smaller
@mixin screen-sm-minus {
  @include screen-max($screen-sm-max) {
    @content;
  }
}

// medium and larger
@mixin screen-md-plus {
  @include screen-min($screen-md-min) {
    @content;
  }
}

// medium and smaller
@mixin screen-md-minus {
  @include screen-max($screen-md-max) {
    @content;
  }
}

// lg and smaller
@mixin screen-lg-minus {
  @include screen-max($screen-lg-max) {
    @content;
  }
}

// xl and smaller
@mixin screen-xl-minus {
  @include screen-max($screen-lg-max) {
    @content;
  }
}
