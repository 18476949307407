@import 'theme/common-style';

.buttonsWrapper {
  @include flex-container(space-between, flex-start, column nowrap, false, 18px);
  width: 100%;
  margin-top: 8px;
  position: relative;
}

.status {
  @include flex-container(space-between, center, row nowrap, false, 12px);
  color: $color-error;
  @include font(14, primary, 600, 20px);
}
