@import 'theme/common-style';

.root {
  @include flex-container(space-between, center);
  @include font(14, primary, 600);
}

.label {
  pointer-events: none;
  cursor: pointer;
}

.switch {
  appearance: none;
  cursor: pointer;
  position: relative;
  width: 31px;
  height: 16px;
  border-radius: 8px;
  background: $color-lavender;
  transition: background-color 0.2s linear;
  margin-left: 12px;
  flex-shrink: 0;

  &::after {
    content: '';
    position: absolute;
    width: 13px;
    height: 13px;
    top: 1.5px;
    left: 1.5px;
    border-radius: 50%;
    background-color: $color-seasalt;
    transition: left 0.2s ease-in-out;
  }

  &:checked {
    background-color: $color-primary;

    &::after {
      left: 16.5px;
    }
  }
}
