@import 'theme/common-style';

.root {
  @include flex-center;
  width: 100%;
  border-radius: 10px;
  border: 1px dashed $color-primary;
  height: 363px;
  position: relative;
}

.fileIcon {
  position: absolute;
  top: 39%;
  left: 50%;
  transform: translate(-50%);
  z-index: 2;
  width: 65px;
  height: 81px;
  opacity: 0.3;

  path {
    fill: $color-lavender;
  }
}

.dragOver {
  border: 2px dashed $color-jade;
}

.dropError {
  border: 2px dashed $color-redrose;
}

.dragBoxInfo {
  position: absolute;
  top: 47%;
  left: 50%;
  transform: translate(-50%);
  @include font(16, primary);
  color: $color-primary;
  z-index: 2;

  p {
    @include screen-md-minus {
      @include flex-container(center, center, column);
    }
  }

  .chooseButton {
    margin-left: 4px;
    color: $color-primary;
    font-size: 16px;
  }
}

.pdfViewer {
  margin-top: 10px;
}

.assetsUploading {
  border: 2px dashed $color-border;
}
