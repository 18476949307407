@import 'src/theme/common-style';

.root {
  @include flex-container(flex-start, flex-start, row nowrap, false, 72px);
}

.container {
  width: 100%;
}

.header {
  width: 100%;
  @include flex-container(space-between, flex-start, row wrap, false, 24px);
  padding-bottom: 32px;
  border-bottom: solid 1px $color-french-gray;
}

.body {
  margin-top: 32px;
}

.descriptions {
  display: grid;
  grid-template-columns: (2.2fr 1fr);
  column-gap: 96px;
}

.notesTitle {
  @include font(17, secondary, 500, 17);
}

.notesBody {
  @include font(14, primary, 400, 20);
  font-style: italic;
  margin-top: 8px;
}

.panelContent {
  margin-bottom: 32px;
}
