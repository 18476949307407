@import 'theme/common-style';

.root {
  width: $setting-sidebar-width;
  height: 100%;
  position: fixed;
  top: 0;
  background: $color-white;
  padding: 74px 25px;
  z-index: 2;

  @include screen-sm-minus {
    position: absolute;
    background-color: $color-lavender;
    background-image: $gradient-lavender-white;
  }

  .background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 211px;
    background-image: $gradient-lavender-white;

    @include screen-sm-minus {
      background-image: none;
    }
  }

  .content {
    position: relative;

    .title {
      @include font(27, secondary, 400, 32);
    }

    .items {
      margin-top: 34px;

      .item {
        width: 100%;
        @include flex-container(space-between);
        @include font(14, secondary, 400);
        letter-spacing: 0.78px;
        margin-bottom: 19px;
        text-align: start;

        &.activeItem {
          color: $color-vista-blue;
        }
      }

      .logout {
        @include flex-container(flex-start, center);
        @include font(14, secondary, 400);
        letter-spacing: 0.78px;

        svg {
          margin-right: 16px;
        }
      }

      .line {
        width: 100%;
        height: 0;
        border-bottom: 2px solid $color-french-gray;
        margin: 32px 0;
      }
    }
  }
}
