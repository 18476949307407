@import 'theme/common-style';

.root {
  @include flex-container(flex-start, flex-start, column);
  width: 410px;
}

.infoCard {
  width: 100%;
}

.labelHeader {
  @include flex-container($gap: 16px);
  @include font(27, secondary, 400, 32);
  margin-bottom: 27px;
  width: 100%;
}
