@import 'theme/common-style';

.root {
  padding: 0 48px 35px;
  position: relative;

  input {
    @include hide-spin-button-on-number-input;
    text-align: end;
  }
}

.maxCommissionInput {
  input {
    padding-right: 50px;
  }
}



.warning {
  @include font(12, primary, 500, 15);
  color: $color-redrose;
  display: none;
  position: absolute;
  left: 48px;
  bottom: 16px;

  &.showWarning {
    display: block;
  }
}

.row {
  @include flex-container(space-between, flex-start, row nowrap, false, 24px);
  margin-bottom: -15px;
  width: 100%;

  &.withError {
    margin-bottom: 32px;
  }

  .currencySelect {
    margin-bottom: 0;

    button {
      width: 122px;
      color: inherit;
    }
  }

  .endIcon {
    bottom: 3px;
  }

  input {
    padding-right: 48px;
  }
}
