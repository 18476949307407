@import 'theme/common-style';
.root {
  @include flex-container(flex-start, flex-start, column nowrap, true, 11px);
  width: 100%;
}
.likeItem {
  @include flex-container(flex-start, center, row nowrap, false, 24px);
  width: 100%;
}
.search {
  width: 344px;
  margin-top: 15px;
}
.text {
  @include font(15, primary, 500, 19);
  min-width: fit-content;
}
