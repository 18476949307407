@import 'src/theme/common-style';

.root {
  @include flex-container(flex-start, flex-start, row nowrap, false, 72px);
}

.wrapper {
  width: 100%;
  @include flex-container(space-between, flex-start, row wrap, false, 24px);
  padding-bottom: 32px;
  border-bottom: solid 1px $color-french-gray;
}
