@import 'theme/common-style';

$gap-small: 16px;
$gap-medium: 11px;
$gap-big: 72px;
$avatar-small: 24px;
$avatar-medium: 32px;
$avatar-big: 168px;

.root {
  @include flex-container(flex-start, stretch);
  gap: 24px;
  width: 100%;

  &.small {
    gap: $gap-small;

    .contentNameAndTitle {
      width: calc(100% - $gap-small - $avatar-small);
    }
  }

  &.medium {
    gap: $gap-medium;

    .contentNameAndTitle {
      width: calc(100% - $gap-medium - $avatar-medium);
    }
  }

  &.big {
    gap: $gap-big;

    .contentNameAndTitle {
      width: calc(100% - $gap-big - $avatar-big);
    }
  }

  &.inactive {
    opacity: 30%;
  }
}

.avatar {
  flex-shrink: 0;
  width: 64px;
  height: 64px;

  &.small {
    width: $avatar-small;
    height: $avatar-small;
  }
  &.medium {
    width: $avatar-medium;
    height: $avatar-medium;
  }
  &.big {
    width: $avatar-big;
    height: $avatar-big;
  }
}

.name, .title, .address, .exclusive, .mandateToSell {
  @include text-line-clamp;
}

.contentName {
  @include flex-container(flex-start, center);
  height: 100%;

  .name {
    @include font(15, primary, 600, 20);
  }
}

.contentNameAndTitle {
  @include flex-container(center, initial, column);
  height: 100%;
  position: relative;

  .name {
    @include font(14, primary, 600, 16);
    text-align: left;
  }

  .title {
    @include font(12, primary, 400, 14);
    margin-top: 2px;
  }

  .tooltip {
    @include font(12, secondary, 500, 14);
    position: absolute;
    width: 120%;
    top: 0;
    left: -10%;
    transform: translateY(calc(-100% - 10px));
    background: $color-primary;
    color: $color-white;
    padding: 8px 10px;
    border-radius: 4px;
    visibility: hidden;

    &::after {
      position: absolute;
      left: 30%;
      content: '';
      bottom: -5px;
      border-top: 5px solid $color-primary;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
    }
  }

  &:hover {
    .tooltip {
      visibility: visible;
    }
  }
}

.contentContact {
  padding: 2px 0;

  .name {
    @include font(15, primary, 700, 20);
  }

  .title,
  .address {
    @include font(14, primary, 400, 20);
  }

  .title {
    @include font-style-italic;
  }

  .address {
    @include flex-container(flex-start, center, row nowrap, false, 1px);

    .icon {
      flex-shrink: 0;
      margin: -2px 0;
    }
  }
}

.contentConsignment {
  padding: 2px 0;

  .name {
    @include font(15, primary, 700, 20);
  }

  .pending {
    font-family: inherit;
    color: $color-redrose;
    margin-left: 0.4em;
  }

  .exclusive,
  .mandateToSell {
    @include flex-container(flex-start, center);
    @include font(14, primary, 400, 20);
    gap: 2px;
  }

  .mandateToSell {
    @include font-style-italic;
    margin-left: -4px;
  }

  .tickWowIcon,
  .tickSmallIcon {
    flex-shrink: 0;
    margin: -2px 0;
  }
}

.profile {
  width: 100%;
  .header {
    @include flex-container(space-between, flex-start, column wrap, false, 24px);
    padding-bottom: 32px;
    border-bottom: solid 1px $color-french-gray;
  }
  .name {
    @include font(46, secondary, 400, 50);
    margin-bottom: 10px;
  }

  .title {
    margin-bottom: 16px;
  }

  .title,
  .url {
    @include font(15, primary);
  }

  .description {
    padding-top: 32px;
    width: 100%;
  }
}
