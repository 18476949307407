@import 'theme/common-style';

.root {
  @include flex-container(flex-end, center, column);
  flex-grow: 1;
  border: 1px solid $color-primary;
  border-radius: 5px;
  height: 80px;
  padding-bottom: 16px;
  cursor: pointer;
  position: relative;

  &.isSelected {
    background-color: $color-lavender-50;
    border-color: $color-lavender-50;
  }
}

.title {
  @include font(12, secondary, 600, 12);
  letter-spacing: 0.67px;
  margin-top: 10px;
}

.tickIcon {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
}
