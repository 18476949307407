@import 'src/theme/common-style';

@include account-form;

.submitButtonContainerBank {
  margin-top: 28px !important;
}

.errorInput {
  margin-bottom: 36px;
}
