.root {
  padding: 8px 0;
  margin: -8px 0;
  position: relative;
}

.dropIndicator {
  position: absolute;
  width: 100%;
  bottom: 4px;
}
