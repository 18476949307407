@import 'src/theme/common-style';

.root {
  .location {
    @include flex-container(flex-start, center, row nowrap, false, 6px);
  }

  &.small {
    .fullName {
      @include font(15, secondary);
    }
    .title {
      @include font(14, primary);
      font-style: italic;
    }
  }

  &.big {
    .fullName {
      @include font(46, secondary);
    }
    .title {
      @include font(15, primary);
      font-style: italic;
      margin-bottom: 18px;
    }
  }
}
