@import 'theme/common-style';

.root {
  width: 100%;

  input {
    text-align: end;
  }
}
.error {
  left: auto;
  right: 0;
}
