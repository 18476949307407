@import 'src/theme/common-style';

.spinner {
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  width: 80px;
  height: 80px;

  path {
    fill: $color-primary;
  }

  &.small {
    width: 20px;
    height: 20px;
  }

  &.white path {
    fill: $color-white;
  }

  &.blue path {
    fill: $color-border;
  }
}

@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
