@import 'src/theme/common-style';

.tooltip {
  @include font(12, secondary, 500, 15);
  letter-spacing: 0.67px;
  background-color: $color-primary;
  color: $color-white;
  padding: 8px 10px;
  border-radius: 4px;
}
