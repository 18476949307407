@import 'theme/common-style';

.root {
  @include flex-container(space-between, flex-end, row nowrap, false, 24px);
  width: 100%;

  input {
    text-align: end;
  }
}

.currency {
  min-width: 95px;
  align-self: flex-end;
}

.error {
  left: auto;
  right: 0;
}
