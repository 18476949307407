@import 'theme/common-style';

.root {
  color: $color-primary;
  margin-bottom: 24px;

  @include font(14);

  &.center {
    width: 100%;
    text-align: center;
  }

  &:not(.center) &:not(.dontLimitWidth) {
    max-width: 85%;
  }
}
