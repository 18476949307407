@import 'theme/common-style';

.root {
  margin: 11px 0 8px;
}

.dragOverlay {
  height: 40px;
  border-radius: 5px;
  background: $color-seasalt;
}

.toggleSwitch {
  margin-left: 8px;
}
