@font-face {
  font-family: 'Nohemi 100';
  src: url('./assets/fonts/Nohemi-Thin.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Nohemi 200';
  src: url('./assets/fonts/Nohemi-ExtraLight.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Nohemi 300';
  src: url('./assets/fonts/Nohemi-Light.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Nohemi 400';
  src: url('./assets/fonts/Nohemi-Regular.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Nohemi 500';
  src: url('./assets/fonts/Nohemi-Medium.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Nohemi 600';
  src: url('./assets/fonts/Nohemi-SemiBold.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Nohemi 700';
  src: url('./assets/fonts/Nohemi-Bold.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Nohemi 800';
  src: url('./assets/fonts/Nohemi-ExtraBold.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Nohemi 900';
  src: url('./assets/fonts/Raleway-Black.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Raleway 100';
  src: url('./assets/fonts/Raleway-Thin.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Raleway 200';
  src: url('./assets/fonts/Raleway-ExtraLight.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Raleway 300';
  src: url('./assets/fonts/Raleway-Light.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Raleway 400';
  src: url('./assets/fonts/Raleway-Regular.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Raleway 500';
  src: url('./assets/fonts/Raleway-Medium.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Raleway 600';
  src: url('./assets/fonts/Raleway-SemiBold.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Raleway 700';
  src: url('./assets/fonts/Raleway-Bold.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Raleway 800';
  src: url('./assets/fonts/Raleway-ExtraBold.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Raleway 900';
  src: url('./assets/fonts/Raleway-Black.ttf') format('truetype');
  font-style: normal;
}

$font-primary: 'Raleway 400', sans-serif;
$font-secondary: 'Nohemi 400', sans-serif;

$sizes: (
  '': 16px,
  '6': 6px,
  '8': 8px,
  '9': 9px,
  '10': 10px,
  '11': 11px,
  '12': 12px,
  '13': 13px,
  '14': 14px,
  '15': 15px,
  '16': 16px,
  '17': 17px,
  '18': 18px,
  '19': 19px,
  '20': 20px,
  '22': 22px,
  '23': 23px,
  '24': 24px,
  '26': 26px,
  '27': 27px,
  '28': 28px,
  '29': 29px,
  '30': 30px,
  '32': 32px,
  '36': 36px,
  '40': 40px,
  '44': 44px,
  '45': 45px,
  '46': 46px,
  '50': 50px,
  '66': 66px,
  '73': 73px,
  '90': 90px,
);

$families: (
  'primary': 'Raleway',
  'secondary': 'Nohemi',
);

@mixin font($size, $family: 'primary', $weight: 400, $height: normal) {
  font-size: map-get($sizes, '#{$size}');
  $fam: map-get($families, '#{$family}');
  font-family: '#{$fam} #{$weight}', sans-serif;
  font-weight: $weight; // to override element's default font-weight
  line-height: map-get($sizes, '#{$height}');
}

@mixin font-style-italic {
  font-style: italic;
  letter-spacing: -0.025em;
  padding-right: 1px;
}
