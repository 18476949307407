@import 'theme/common-style';

@mixin fonts-on-banner($weight: 400) {
  @include font(13, secondary, $weight, 13);

  @include screen-max(720px) {
    @include font(11, secondary, $weight, 11);
  }
}

.root {
  margin-left: $sidebar-width;
  width: 100%;

  @include screen-sm-minus {
    margin-left: 0;
  }

  .outer {
    width: 100%;
    height: 32px;
    padding-top: 1px;
    text-align: center;
    @include flex-container(flex-start, center);
    color: $color-white;
    background-color: $color-error;
    @include fonts-on-banner;
    letter-spacing: 0.8px;
    position: fixed;
    z-index: 4;

    & * {
      @include fonts-on-banner;
      letter-spacing: 0.8px;
    }

    a {
      @include fonts-on-banner(600);
    }

    .inner {
      width: calc(100vw - $sidebar-width);

      @include screen-sm-minus {
        width: 100vw;
      }
    }
  }
}
