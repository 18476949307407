@import 'theme/common-style';

.root {
  @include flex-container(flex-start, flex-start, column nowrap, false, 32px);
}

.header {
  @include flex-container(flex-start, flex-start, column nowrap, false, 8px);
  width: 100%;
}

.picture {
  align-self: center;
  width: 168px;
  height: 168px;
}

.form {
  width: 100%;
  @include flex-container(flex-start, flex-start, column nowrap, false, 22px);
}

.section {
  width: 100%;
  border-top: 1px solid $color-french-gray;
  padding-top: 32px;
}
