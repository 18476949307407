@import 'src/theme/common-style';

.iconAndInfoContainerRegular {
  @include flex-container(flex-start, center, $gap: 10.5px);
}

.iconAndInfoContainerIsUnder {
  @include flex-container(center, center, column, $gap: 5px);
  width: 100%;
  height: 71px;
}

.firstInTimeline {
  @include flex-container(center, flex-start, column, $gap: 5px);
}

.lastInTimeline {
  @include flex-container(center, flex-end, column, $gap: 5px);
}

.infoText {
  @include font(14, primary, 600, 20);
  @include flex-container(space-between, center, $gap: 8px);
  color: $color-primary;

  span {
    @include font(13, primary, 400, 20);
  }
}

.timelineContainer {
  @include flex-container(flex-start, center);
}

.infoTextInColumn {
  @include flex-container(center, center, column, $gap: 4px);
}
