@import 'src/theme/common-style';

$select-height: 40px;
$select-height-as-text: 26px;

.root {
  position: relative;
  margin-bottom: 15px;

  &.asText {
    margin-bottom: 0;
  }

  &.hasSideLabel {
    @include flex-center;
  }

  .inputWrapper {
    margin-bottom: 0;
  }

  .dropdown {
    position: absolute;
    left: 0;
    top: calc($select-height + 26px);
    @include flex-container(flex-start, center, column, false, 2px);
    width: 100%;
    background-color: $color-white;
    border-radius: 0 0 5px 5px;
    box-shadow: $box-shadow;
    max-height: 168px;
    @include vertical-scrollbar;
    z-index: 2;
    padding: 1px 0;

    &.withoutLabel {
      top: calc($select-height + 2px);
    }

    &.asText {
      top: calc($select-height-as-text + 2px);
    }

    &.noItems {
      padding: 0;
    }

    .option {
      @include font(15);
      padding: 6px 12px;
      cursor: pointer;
      @include flex-container(space-between, center);
      color: $color-primary;
      width: 99%;
      border: 1px solid transparent;

      &.asText {
        @include flex-container(flex-end, center);
        padding: 6px 28px 6px 3px;

        &.selected {
          padding: 6px 3px;
        }

        .icon {
          margin-left: 4px;
        }
      }

      &.highlighted, &:not(.navigatingByArrows):hover {
        border: 1px solid $color-border;
        border-radius: 5px;
      }
    }
  }

  .label {
    @include font(13, primary, 400);
    display: block;
    color: $color-primary;
    margin-bottom: 6px;

    &.onSideLabel {
      @include flex-container;
      width: 100%;
      margin: 0;
    }
  }

  .button {
    padding: 0 13px;
    background-color: $color-lavender-50;
    color: $color-gray-placeholder;
    border-radius: 5px;
    border: 1px solid transparent;
    @include flex-container(space-between, center);
    cursor: pointer;
    height: $select-height;
    transition: all 0.2s ease-in-out;
    width: 100%;

    .selectedValue.asFormElement {
      @include font(17, primary, 500, 19);
      @include flex-container(flex-start, center);
      color: $color-primary;
    }

    .placeholder, .selectedValue:not(.asFormElement) {
      @include font(15, primary, 400, 19);
      @include flex-container(flex-start, center);
    }

    &.open {
      border-radius: 5px 5px 0 0;
      border: 1px solid $color-border;
    }

    .icon {
      @include flex-center;
    }

    .iconOpen {
      transform: rotate(180deg);

      &.iconClose {
        transform: rotate(0);
      }
    }

    &.errorButton {
      border: 1px solid $color-error;
      background-color: $color-white;
    }

    &.asText {
      @include flex-container(flex-end, center);
      height: $select-height-as-text;
      padding: 0;
      background-color: initial;
      color: initial;
      border-radius: 0;
    }
  }

  .error {
    @include font(13, primary, 500);
    position: absolute;
    color: $color-error;
    top: calc(100% + 3px);
    left: 0;
  }
}
