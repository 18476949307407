@import 'theme/common-style';

@mixin btn-hover($color) {
  &:hover:not(:disabled):not(.loading):not(.specialMarkingBlocked) {
    background-color: darken($color, 10%);
  }
}

@mixin regular-button {
  @include font(12, secondary, 600, 12);
  letter-spacing: 0.67px;
  text-transform: uppercase;
  padding: 0 26px;
  height: 40px;
  width: fit-content;
  white-space: nowrap;
  transition: background-color 0.2s ease-in-out;
  border-radius: 5px;
  border: 1px solid $color-primary;

  &.withoutUpperCase {
    text-transform: none;
  }
}

.root {
  @include flex-container(center, center, row nowrap, true, 6px);
  position: relative;
  outline: none;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }

  &:disabled:not(.tertiary):not(.textButton) {
    background-color: $color-french-gray !important;
    color: $color-white !important;
    border: none !important;
  }

  &.primary {
    @include regular-button;
    @include btn-hover($color-primary);
    background-color: $color-primary;
    color: $color-white;
  }

  &.secondary {
    @include regular-button;
    @include btn-hover($color-white);
    color: $color-primary;
    background-color: transparent;
  }

  &.tertiary {
    @include regular-button;
    background-color: transparent;
    border: none;
    padding: 0;
    height: auto;

    .text {
      padding-left: 6px;
      padding-top: 4px;
    }
  }

  &.textButton {
    @include font(14, primary, 600, 19);
    text-decoration: underline;
    background-color: transparent;
    border: none;
    width: fit-content;
  }

  &.maxWidth {
    width: 100%;
  }

  &.loading {
    padding: 18px 22px 18px 50px;
    cursor: default;
  }

  &.specialMarkingBlocked {
    background-color: $color-french-gray;
    color: $color-white;
    border: none;
    cursor: default;
    padding-left: 60px;
  }

  &.specialMarkingPaid {
    padding-left: 60px;
  }

  .loader {
    margin-left: 8px;
  }

  .text {
    font: inherit;
    padding-top: 2px;

    &.light {
      color: $color-white;
    }
  }

  .additionalMessageWrapperOuter {
    width: 44px;
    height: 100%;
    border-right: 1px solid $color-white;
    position: absolute;
    left: 0;

    .additionalMessageWrapperInner {
      @include flex-center;
      width: 100%;
      height: 100%;
      position: relative;

      &.pinkIcon {
        color: $color-redrose;
      }

      &.whiteIcon {
        color: $color-white;
      }

      &:hover {
        .tooltipWrapper {
          display: block;
        }
      }

      .tooltipWrapper {
        display: none;
        position: absolute;
        bottom: 52px;
        left: 0;

        .tooltip {
          color: $color-white;
          max-width: 294px;
          width: max-content;
          text-align: left;
          padding: 8px 9px;
          @include font(12, secondary, 500, 16);
          border-radius: 4px;
          text-transform: none;
          position: relative;
          white-space: initial;

          &.blackTooltip {
            background-color: $color-primary;

            &:before {
              border-top: 5px solid $color-primary;
            }
          }

          &.pinkTooltip {
            background-color: $color-redrose;

            &:before {
              border-top: 5px solid $color-redrose;
            }
          }

          &:before {
            content: "";
            position: absolute;
            top: 100%;
            left: 10px;
            width: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
          }

        }
      }
    }
  }
}
