@import 'theme/common-style';

.root {
  padding: 56px 24px 24px;
  background-color: $color-white;
}

.codeColumn {
  @include font(19, secondary, 500, 33);
}

.buttons {
  @include flex-container($flex-flow: column, $gap: 16px);
  margin-top: 52px;
}

.blocks {
  display: flex;
}

.block {
  @include flex-container(center);
  flex: 1;
}

.code {
  @include font(19, secondary, 500, 32);
  text-align: center;
  letter-spacing: 0.67px;
  margin: 0 1px;
}
