@import 'theme/common-style';

.root {
  width: 100%;
}

.rowWrapper {
  width: 100%;
  @include flex-container(space-between, center);
  margin-bottom: 24px;
  cursor: pointer;

  &.pendingOwnersAgreement {
    opacity: 30%;
    margin-bottom: 4px;
  }
}

.awaitingApproval {
  margin-left: 88px;
  color: $color-error;
  @include font(14, primary, 600, 20px);
}

.action {
  margin-top: 26px;
}

.createOfferWrapper {
  margin-top: 40px;
}

.consignmentItem {
  margin-bottom: 40px;
}
