@import 'theme/common-style';

.root {
    @include flex-container(flex-start, flex-start, column, false, 16px);
  }
  
  .itemWrapper {
    @include flex-container(space-between);
    width: 100%;
    padding-left: 1px;
  
    .checkboxLabel {
      @include font(15, primary, 500);
    }
  }
  
  .number {
    @include font(13);
    color: $color-primary-60;
  }