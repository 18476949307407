@import 'theme/common-style';

.root {
  @include flex-container(space-between, flex-start, row nowrap, false, 32px);
  padding-top: 8px;
}
.tile {
  min-height: 94px;
}
.mainColumn {
  @include flex-container(flex-start, flex-start, column nowrap, false, 32px);
  width: 528px;
}

.sectionWrapper {
  @include flex-container(space-between, flex-start, row nowrap, false, 48px);
  width: 100%;
  position: relative;
}

.buttonsWrapper {
  @include flex-container(space-between, flex-start, column nowrap, false, 18px);
  width: 100%;
  margin-top: 8px;
  position: relative;
}

.formError {
  position: absolute;
  left: 0;
  top: -25px;
}

.image {
  background-color: $color-white;
  object-fit: contain;
  width: 88px;
  height: 88px;
}

.imagesList {
  @include flex-container(flex-start, center, row wrap, false, 24px);
}

.ownersList {
  @include flex-container(flex-start, flex-start, column, false, 12px);
  width: 100%;
}

.error {
  color: $color-error;
}

.noArtworkFound {
  width: 100%;
  margin-top: 100px;
  @include flex-container(center, center, column, false, 12px);
}
