@import 'theme/common-style';

.control {
  @include flex-container(space-between, center, row-reverse);
  width: 100%;
  border-top: 1px solid $color-french-gray;
  height: 72px;

  &:focus-visible {
    outline: 2px solid $color-vista-blue;
  }
}

.label {
  @include flex-container(flex-start, center);
  @include font(12, secondary, 600);
  text-transform: uppercase;
  letter-spacing: 0.67px;
  width: 100%;
  padding-right: 24px;
}

.chevronContainer {
  @include flex-container(space-between, center);
  width: 100%;
}

.content {
  padding-bottom: 32px;
}
