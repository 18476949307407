@import 'src/theme/common-style';

.root {
  display: grid;
  gap: 24px 40px;
  margin-top: 40px;
  word-wrap: break-word;

  &.isSmall {
    grid-template-columns: repeat(auto-fill, 172px);
  }

  &.isMedium {
    grid-template-columns: repeat(auto-fill, 199px);
  }

  &.isBig {
    grid-template-columns: repeat(auto-fill, 256px);
  }

  .tile {
    padding: 8px;
    // padding: 8px 8px 40px 8px; // TODO uncomment when ready (when we uncomment div.hiddenElementsWrapper)
    position: relative;
    cursor: pointer;

    &.isSelected,
    &:hover {
      background-color: $color-lavender-30;

      .hiddenElements {
        @include flex-container(space-between, center);
        display: flex !important;
      }
    }

    .hiddenElementsWrapper {
      height: 40px;
      padding: 8px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;

      .hiddenElements {
        display: none;
      }
    }

    .content {
      margin-bottom: 19px;
      // display: flex;
      // flex-wrap: wrap; // uncomment to put items in the same line if they fit
    }
  }
}
