@import 'src/theme/common-style.scss';

.root {
  position: relative;
  width: 100%;

  .button {
    @include flex-container(space-between);
    width: 100%;

    .buttonContent {
      min-width: 0;
    }

    .arrow {
      transition: transform 0.2s;
      align-self: center;

      &.turnedArrow {
        transform: rotate(-180deg);
      }
    }
  }

  .dropupWrapper {
    overflow: hidden;
    transition: height 0.2s;
    border-radius: 11px;
    display: none;
    z-index: 2;

    &.showDropup {
      display: block;
    }

    .dropup {
      padding: 14px 32px 14px 24px !important;
    }

    .item {
      height: 27px;
      padding: 4px 0;
      display: block;
      white-space: nowrap;
      @include font(12, primary, 400);
      text-decoration: none;
    }
  }
}
