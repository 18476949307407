@import 'src/theme/common-style';

.root {
  @include font(14, primary, 500, 20);
  @include flex-container(flex-start, center);
  margin-top: -20px;
  margin-bottom: 30px;

  svg {
    margin-right: 7px;
    min-width: 28px;
  }
}
