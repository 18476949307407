@import 'theme/common-style';

.root {
  width: 100%;

  .accordionMenuSubtitle {
    @include font(15, primary, 500, 19);
    margin-top: 32px;
    margin-bottom: 8px;

    &.firstElement {
      margin-top: 0;
    }
  }
}
