@import 'src/theme/common-style';

.root {
  @include flex-container(space-between, center);
  border-bottom: 1px solid $color-primary;
  padding-bottom: 14px;
}
.leftSide {
  @include flex-container(flex-start, center, row nowrap, false, 32px);
}

.rowsPerPage {
  @include flex-container(flex-start, center, row nowrap, false, 8px);

  .text {
    @include font(11);
  }

  .select {
    margin-bottom: 0;

    button {
      height: 32px;
      width: 53px;
    }
  }
}

.number {
  @include font(11, secondary);
}
