@import 'theme/common-style';

.overlay {
  position: fixed;
  inset: 0;
  background-color: rgba($color-gray-light, 0.49);
  z-index: 999;
  overflow: auto;
  animation: displayModal 150ms ease-in;

  .modal {
    background: $color-white;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
    outline: none;
    padding: 48px;
    width: 624px;
    position: relative;
    box-shadow: $box-shadow;
    color: $color-primary;

    .title {
      margin-bottom: 24px;
      @include font(27, primary, 600, 32);
    }

    .subtitle{
      @include font(14, primary, 400, 20);
    }

    .closeBtn {
      @include flex-center;
      position: absolute;
      background-color: transparent;
      border: none;
      padding: 5px;
      top: 16px;
      right: 16px;
      cursor: pointer;
    }

    .actions{
      @include flex-container(flex-end, center, row nowrap, false, 24px);
      margin-top: 32px;
    }
  }
}


@keyframes displayModal {
  0% {
    transform: scale(1.2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
