@import 'theme/common-style';

.root {
  @include flex-container(flex-start, center, row wrap, false, 24px);
  width: 100%;
}

.asset {
  @include flex-container(space-between, center, row wrap, false, 24px);
  width: 100%;
}

.assetDetails {
  @include flex-container(space-start, center, row wrap, false, 12px);
  &.left {
    @include font(13, primary, 600, 20);
  }

  &.right {
    @include font(13, primary, 400, 20);
  }
}
