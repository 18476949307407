@import 'theme/common-style';

.root,
.root * {
  @include font(46, secondary, 400, 50);
  color: $color-primary;
  margin-bottom: 24px;
  max-width: 75%;
}

.root {
  &.dontLimitWidth {
    max-width: 100%;
  }
}
