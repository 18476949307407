$color-black: #020408;

$color-white: #ffffff;
$color-white-semi-transparent: rgba(255, 255, 255, 70%);

$color-primary: #282828;
$color-primary-13: rgba(40, 40, 40, 0.13);
$color-primary-60: rgb(40, 40, 40, 60%);

$color-french-gray: #cbcada;

$color-lavender: #d8d7e2;
$color-lavender-30: #edecee; // rgba(216, 215, 226, 30%);
$color-lavender-50: rgba(216, 215, 226, 50%);

$color-vista-blue: #a2a5d9;
$color-seasalt: #f8f8f8;

$color-redrose: #c52e5d;
$color-redrose-24: #f0ccd7;

$color-jade: #00a676;
$color-maize: #e9e33c;

// other colors - to check if used
$color-gray-light: #d4dae8;
$color-orange: #ff7700;
$color-mustard-yellow: #ebb037;

// color by its function
$color-border: $color-vista-blue;
$color-border-dark: $color-primary;

$color-gray-placeholder: #737373;

$color-error: $color-redrose;
$color-warning: $color-mustard-yellow;
$color-success: $color-jade;
$color-success-alt: #44af69;

$box-shadow: 0 2px 25px 0 rgba(58, 82, 129, 0.21);
$gradient-lavender-white: linear-gradient(
  90deg,
  $color-lavender-30 0%,
  rgba(255, 255, 255, 0.448645) 56%,
  $color-white 100%
);

$gradient-lavender-white-180: linear-gradient(
  180deg,
  $color-lavender-30 0%,
  rgba(255, 255, 255, 0.448645) 56%,
  $color-white 100%
);
