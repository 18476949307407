@import 'src/theme/common-style';

.root {
  @include flex-container(space-between, center, $gap: 1em);
  @include font(12, secondary, 600);
  width: 100%;

  .items {
    @include font(15, primary, 600);
    text-transform: none;
    max-width: 220px;
    @include text-overflow-ellipsis;
  }
}
