@import 'theme/common-style';

.root {
  @include flex-container(space-between, center);
  width: 100%;
  padding-left: 1px;
  margin-bottom: 16px;
}

.select {
  width: 354px;
  margin-bottom: 0;
}

.unclickable {
  opacity: 0.5;
}
.unclickable, .unclickable * {
  cursor: not-allowed;

  input::before {
    cursor: not-allowed;
  }
}
