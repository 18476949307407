@import 'theme/common-style';

.root {
  @include flex-container(flex-start, flex-start, column, false, 12px);
  width: 100%;
}

.sectionWrapper {
  @include flex-container(space-between, flex-start, row nowrap, false, 48px);
  width: 100%;
}
