@import 'src/theme/common-style';

.root {
  @include scrollable-table(592px);

  .row {
    width: 100%;
    @include flex-container(space-between, center);
    padding: 12px 0;
    cursor: pointer;

    &:hover {
      background-color: $color-lavender-30;

      .hiddenBtns {
        display: flex;
        gap: 12px;
      }
    }
  }

  td {
    @include flex-container(center, flex-start);
    flex-direction: column;
    column-gap: 12px;
    width: 250px;
    padding: 0 10px;

    &.chipTd {
      width: 88px;
      padding: 0 16px 0 0;
      row-gap: 8px;
    }

    &.imageTd {
      width: 84px;

      img {
        background-color: $color-white;
        object-fit: contain;
        width: 64px;
        height: 64px;
      }
    }

    &.mainDataTd {
      width: 280px;
      padding: 0 16px;
    }

    &.idTd {
      width: 175px;
    }

    &.dateTd {
      width: 110px;
    }

    &.primaryContactTd {
      width: 280px;
      @include flex-container(flex-start, center, row wrap, false, 16px);
    }

    &.statusTd {
      @include flex-container(flex-start, center, row nowrap, false, 12px);
      width: 290px;
      @include font(14, primary, 600, 20);
    }
  }
}
