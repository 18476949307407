@import 'src/theme/common-style';

.dot {
  @include flex-center;
  width: 12px;
  height: 12px;

  &::after {
    content: '';
    width: 9px;
    height: 9px;
    border-radius: 50%;
  }

  &.green {
    &::after {
      background-color: $color-jade;
    }
  }

  &.yellow {
    &::after {
      background-color: $color-maize;
    }
  }

  &.violet {
    &::after {
      background-color: $color-vista-blue;
    }
  }

  &.red {
    &::after {
      background-color: $color-redrose;
    }
  }

  &.redEmpty {
    &::after {
      border: 1px solid $color-redrose;
    }
  }
}
