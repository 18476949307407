@import 'theme/common-style';

.root {
  @include flex-container(flex-start, center);
  padding: 23.5px 25px;
  min-width: 498px;
  // height: 91px;
  color: $color-white;
  position: relative;

  & ~ & {
    margin-top: 12px;
  }
}

.closeIcon {
  position: absolute;
  top: 17px;
  right: 16px;
  cursor: pointer;
}

.icon {
  @include flex-container(center, center);
  width: 44px;
  height: 44px;
  background-color: $color-white;
  border-radius: 50%;

  &-success svg {
    path:nth-child(2) {
      fill: $color-success;
    }
  }

  &-error svg {
    path {
      fill: $color-error;
    }
  }

  &-warning svg {
    path {
      fill: $color-orange;
    }
  }
}

.label {
  @include font(12);
  letter-spacing: 0.6;
  display: block;
  text-transform: uppercase;
}

.success {
  background-color: $color-success-alt;
}
.error {
  background-color: $color-error;
}
.warning {
  background-color: $color-orange;
}

.text-success,
.text-error,
.text-warning {
  color: $color-white;
}

.textContainer {
  margin-left: 23px;
  max-width: 480px;
}

.childrenContainer {
  @include font(14, primary, 700);
}
