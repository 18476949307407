@import 'theme/common-style';

.root {
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  flex-shrink: 0;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.placeholder {
  @include flex-container(center, center);
  width: 100%;
  height: 100%;
  background-size: 100%;
  color: $color-white;

  svg {
    width: 57%;
    height: 57%;
  }
}
