@import 'src/theme/common-style';

.root {
  @include scrollable-table(949px);

  .row {
    width: 100%;
    @include flex-container(space-between, center);
    padding: 12px 0;

    &.clickable {
      cursor: pointer;
    }

    &:hover {
      background-color: $color-lavender-30;

      .hiddenBtns {
        display: flex;
        gap: 12px;
      }
    }
  }

  .hiddenBtns {
    gap: 14px;
    display: none;
  }

  td {
    @include flex-container(center, flex-start);
    flex-direction: column;
    column-gap: 12px;
    width: 250px;
    padding: 0 10px;

    &.avatarTd {
      height: 64px;
      width: 78px;
      padding: 0 14px 0 0;
      align-items: initial;

      .avatar {
        width: 64px;
        height: 64px;
      }
    }

    &.mainDataTd {
      width: inherit;

      .mainData {
        max-width: 50vw;
      }
    }

    &.checkboxTd {
      width: 36px;
    }

    &.tagsTd {
      @include flex-container(flex-start, center, row wrap, false, 12px);

      .tag {
        background-color: $color-white;
        border-radius: 20px;
        padding: 4px 16px;
        @include font(13, primary, 600);
      }
    }

    &.networkActionsTd {
      width: 118px;
    }

    &.requestedActionsTd {
      width: 144px;
    }

    &.pendingActionsTd {
      width: 262px;
    }

    &.primaryContactTd {
      width: 300px;
      @include flex-container(flex-start, center, row wrap, false, 16px);
    }
  }

  .actionBtns {
    @include flex-container(space-between, center, row-reverse, false, 14px);
    width: 100%;
  }
}
