@import 'theme/common-style';

.root {
  position: relative;

  .fetchIdBtnWrapper {
    margin-top: 50px;
    @include flex-center;
  }

  .infoForVerifiedUser {
    position: absolute;
    top: 280px;
    width: 100%;

    .resetStatusCopy {
      text-align: center;
      margin-bottom: 32px;
      height: 152px;
      width: 100%;

      .hideResetStatusCopyInner {
        display: none;
      }

      .highlightedText {
        @include font(16, primary, 600);
      }

      p {
        margin-top: 16px;
      }
    }
  }
}

.btnWrapper {
  margin-top: 16px;
  @include flex-center;
}
