@import 'src/theme/common-style';

.root {
  position: relative;
  width: 100%;

  &.gridSmall,
  &.gridMedium,
  &.gridBig {
    overflow: hidden;
    margin-top: 8px;
  }

  &.onlyItemInLine {
    width: 100%;
  }
}

.image {
  width: 100%;

  .img {
    background-color: $color-white;
    object-fit: contain;
  }

  &.listSmall {
    .img {
      height: 44px;
      width: 44px;
    }
  }

  &.listMedium {
    .img {
      height: 64px;
      width: 64px;
    }
  }

  &.listBig {
    .img {
      height: 88px;
      width: 88px;
    }
  }

  &.gridSmall {
    @include flex-container(center);
    margin-bottom: 25px;

    .img {
      height: 157px;
      width: 157px;
    }
  }

  &.gridMedium {
    @include flex-container(center);
    margin-bottom: 25px;

    .img {
      height: 182px;
      width: 182px;
    }
  }

  &.gridBig {
    @include flex-container(center);
    margin-bottom: 25px;

    .img {
      height: 240px;
      width: 240px;
    }
  }
}

.author {
  @include font(15, primary, 700, 20);

  &.gridSmall,
  &.listSmall {
    @include font(14, primary, 700, 20);
  }
}

.nameYear {
  @include font(14, primary, 400, 20);

  &.gridSmall,
  &.listSmall {
    @include font(13, primary, 400, 20);
  }

  &.gridSmall,
  &.gridMedium,
  &.gridBig {
    margin-top: 0;
  }

  span {
    font-style: italic;
  }
}

.id {
  @include font(14, secondary, 400, 20);

  &.gridSmall,
  &.listSmall {
    @include font(13, secondary, 400, 20);
  }
}

.tags {
  @include font(13, primary, 400, 20);

  &.gridSmall,
  &.listSmall {
    @include font(12, primary, 400, 20);
  }
}

.visibilityIcon,
.statusIcon {
  &.listSmall,
  &.listMedium,
  &.listBig {
    @include flex-container(flex-start, center);
    height: 20px;

    &:only-child,
    & + .statusIcon,
    &:has(+ .statusIcon),
    & + .visibilityIcon,
    &:has(+ .visibilityIcon) {
      @include flex-center;
    }
  }
}

.statusIcon {
  padding-left: 2px;
  padding-right: 2px;
}

.cutContentWrapper {
  @include text-overflow-ellipsis;
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
}

.uncutContentWrapper {
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
  position: absolute;
  top: 0;
  z-index: 1;
  width: max-content;
  background-color: $color-lavender-30;
  padding-right: 8px;
}

.iconWrapper {
  // display: flex;
  // @include flex-center;
  // width: 100%;
  background-color: lightblue;
}
