@import 'src/theme/common-style';

.root {
  @include flex-container(flex-start, center, row, false, 16px);
}

.labelText {
  @include font(11);

  .error {
    color: $color-error;
    margin-left: 4px;
  }
}

.input {
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 1px;
  border: 1px solid $color-primary;
  align-self: center;
  display: grid;
  justify-content: center;
  align-content: end;

  &:hover {
    cursor: pointer;
  }

  &:focus,
  &:hover {
    outline: 1px solid $color-border;
  }

  &.primary::before {
    content: url('../../assets/svg/checkOn.svg');
  }

  &.secondary::before {
    content: url('../../assets/svg/checkNeutral.svg');
  }

  &.primary::before,
  &.secondary::before {
    font-family: none;
    width: 16px;
    height: 16px;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
  }

  &:checked::before {
    transform: scale(1);
  }
}
