@import 'theme/common-style';

.content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  column-gap: 48px;
  margin-bottom: 32px;
}

.sectionTitle {
  grid-column: 1/3;

  @include screen-md-plus {
    grid-column: 1/2;
  }
}

.author {
  @include font(45, secondary, 400, 50);
}

.name {
  @include font(15, primary, 400, 20);
  font-style: italic;
  margin-top: 10px;
}

.year {
  font-style: normal;
}

.sectionImage {
  @include flex-container(center, flex-start);
  grid-column: 1/3;
  margin-top: 40px;

  @include screen-md-plus {
    grid-column: 2/3;
    grid-row: 1/3;
    margin-top: 0;
    justify-content: end;
  }
}

.image {
  min-width: 240px;
  width: 240px;
  max-height: 50vh;
  object-fit: contain;
}

.sectionDescription {
  grid-column: 1/3;
  margin-top: 40px;
  align-self: end;

  @include screen-md-plus {
    grid-column: 1/2;
  }
}

.description {
  @include font(14, primary, 400, 20);
}

.id {
  @include font(14, secondary, 400, 20);
  margin-top: 5px;
}

.visibility {
  @include font(13, primary, 400, 20);
  @include flex-container(flex-end, center);
  margin-top: 26px;

  @include screen-md-plus {
    justify-content: start;
  }
}

.visibilityLabel {
  margin-right: 16px;
}

.visibilityStatus {
  @include font(13, primary, 600);
  margin-left: 5px;
}

.details {
  margin-top: 48px;
}

.status {
  margin-bottom: 48px;
}
