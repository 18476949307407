@import 'src/theme/common-style';

@mixin highlightedRow {
  background-color: $color-lavender-30;
}

.root {
  @include scrollable-table(977px);
  padding-bottom: 8px;

  td {
    @include flex-container(center, flex-start);
    flex-direction: column;
    column-gap: 12px;
    width: 250px;
    padding: 0 10px;
    height: 88px;

    &.checkboxTd {
      width: 22px;
      padding: 0;
    }
  }

  .rowOuterTable {
    width: 100%;
    @include flex-container(space-between, flex-start);

    &.highlightedRow {
      @include highlightedRow;
    }

    .imageTd {
      width: 84px;

      img {
        background-color: $color-white;
        object-fit: contain;
        width: 64px;
        height: 64px;
      }
    }

    .moreDataTd {
      width: calc(100% - 84px);
      padding: 0 0 0 6px;
      height: initial;

      .basicData {
        @include flex-container(center, flex-start, column nowrap);
        height: 88px;
      }

      .innerTableWrapper {
        width: 100%;
      }
    }
  }

  .rowInnerTable {
    width: 100%;
    @include flex-container(space-between, center);
    padding: 4px 0;
    cursor: pointer;

    &:hover {
      @include highlightedRow;
    }

    td {
      height: initial;
    }

    .primaryContactTd {
      width: 344px;
      @include flex-container(flex-start, center, row nowrap, false, 16px);
      padding-left: 4px;

      .direction {
        @include font(14);
        width: 48px;
        flex-shrink: 0;
      }
    }

    .exclusiveTd {
      width: 120px;
      @include flex-container(flex-start, center);
      @include font(14, primary, 400, 20);
    }

    .canSellTd {
      width: 210px;
      @include flex-container(flex-start, center);
      @include font(14, primary, 400, 20);
      font-style: italic;
    }

    .priceTd {
      @include font(15, primary, 700, 20);
      @include flex-container;
      min-width: 320px;
      white-space: normal;
    }

    .expiresTd {
      //width: 130px;// REVERT
      width: 150px;
      @include font(13, primary, 400, 20);
    }
  }
}
