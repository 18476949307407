@import 'theme/common-style';

.root {
  @include flex-container(center, center);
  width: 100%;
  height: 100%;
}

.container {
  padding-bottom: 36px;
  width: 320px;
}

.avatar {
  position: relative;
  z-index: 1;
  width: 64px;
  height: 64px;
  margin: 0 auto -13px;
}

.qrCodeBox {
  background-color: $color-white;
  border-radius: 5px;
  padding: 24px;
}

.qrCode {
  @include flex-container(center, center);
  width: 275px;
  height: 275px;
}

.organizationName {
  @include font(27, secondary, 400, 32);
  text-align: center;
  margin-top: 16px;
  word-break: break-word;
}

.organizationTitle {
  @include font(14, primary, 400, 20);
  @include font-style-italic;
  text-align: center;
}

.linkBox {
  @include flex-container(space-between, center);
  background-color: $color-lavender;
  margin-top: 40px;
  border-radius: 5px;
  padding: 0 16px;
  height: 50px;
  gap: 12px;

  &.isHidden {
    visibility: hidden;
  }
}

.linkInput {
  @include font(15);
  width: 100%;
  border: none;
  background-color: transparent;
  color: $color-gray-placeholder;
  outline: none;

  &::-moz-selection {
    color: $color-black;
    background: $color-vista-blue;
  }
  &::selection {
    color: $color-black;
    background: $color-vista-blue;
  }
}

.icon {
  @include flex-container(center, center);
  color: $color-black;
  cursor: pointer;

  &:active {
    color: $color-vista-blue;
  }
}
