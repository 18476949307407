@import 'theme/common-style';

.root {
  margin-bottom: 45px;

  h2.header {
    @include font(27, secondary, 400, 32);
    margin-bottom: 28px;
    width: 100%;
    @include flex-container(flex-start, center, $gap: 10px);
  }

  .sliderContent {
    margin-top: 10vh;
  }
}
