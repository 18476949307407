@import 'theme/common-style';

.categories {
  @include flex-container(flex-start, flex-start, row nowrap, false, 34px);
  margin-bottom: 48px;
  overflow: auto;
  @include horizontal-scrollbar;

  li {
    white-space: nowrap;
    list-style: none;
    @include font(45, secondary, 400, 50);
    color: $color-primary;
    opacity: 0.2;
    cursor: pointer;

    &.active {
      opacity: 1;
    }
  }
}
